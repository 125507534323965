import React, {useEffect, useState} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as offerActions from '../../actions/offers';
import {resetOfferClient} from '../../actions/offers';
import * as clientActions from '../../actions/client';
import {resetClient} from '../../actions/client';
import * as measuresActions from '../../actions/measures'
import * as adminActions from '../../actions/admin';
import {withRouter} from 'react-router';
import {EInputTextType, Input} from '../../components/Input/Input';
import {Button, EButtonType} from '../../components/Button';
import './Offers.scss'
import {Client} from '../../types/client.type';
import {toast} from 'react-toastify';
import Tooltip from "rc-tooltip";
import clearFormSvg from '../../assets/icons/archeology.svg'
import Spinner from '../../components/Spinner';
import MikaSelect from '../../components/BbrosSelect/mikaSelect.component';
import {SelectOption} from '../../types/selectOption.type';
import {FileUpload} from '../../types/fileUpload.type';
import {OfferField} from '../../types/offerField.type';
import DataTransferComponent from "../../components/DataTransferComponent/DataTransferComponent";
import arrowDown from '../../assets/icons/Icon_awesome_arrow_alt_circle_right.svg'
import {useParams} from "react-router-dom";
import {Asset} from "../../types/asset.type";
import {isMobile, OFFER_STATUSES} from "../../constants/constants";
import MikaFileInput from "../../components/Input/MikaFileInput/MikaFileInput";
import CalculateOfferModal from "./CalculateOfferModal/CalculateOfferModal";
import FontAwesome from 'react-fontawesome'
import {ParameterTypes} from '../../types/parameter.type';
import {checkIfUserIsAuthorized} from "../Admin/globalFunctions";
import PICFile from "../../assets/icons/pic.svg";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import {axiosCaller} from "../../utils/apiCaller";
import {OfferProduct} from "../../types/offerProduct.type";
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    intl: any;
    loading: boolean;
    history: any;
    client: any;
    clientActions: any;
    offerActions: any;
    measuresActions: any;
    actions: any,
    offers: any,
    measures: any,
    letter: string,
    clientsByModuleData: Array<Client>,
    allClients: SelectOption[],
    allOfferFields: OfferField[],
    adminActions: any,
    admin: any,
    permissions: any,
    projectName: string
    buttonLoader: boolean
}

const Offers = (props: Props) => {

    const {offerId, projectId} = useParams<ParameterTypes>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);
    const [firstAndLastName, setFirstAndLastName] = useState<string>('');
    const [offerStatus, setOfferStatus] = useState<SelectOption>()
    const [addition, setAddition] = useState<string>('')
    const [finalOfferFile, setFinalOfferFile] = useState<FileUpload[]>([] as FileUpload[])
    const [requirementsFile, setRequirementsFile] = useState<FileUpload[]>([] as FileUpload[])
    const [pdfFile, setPdfFile] = useState<FileUpload[]>([] as FileUpload[])
    const [searchedClient, setSearchedClient] = useState<string>()
    const [clients, setClients] = useState<SelectOption[]>([])
    const [allOfferFields, setAllOfferFields] = useState<OfferField[]>([])
    const [isDropDownInfoOpen, setIsDropDownInfoOpen] = useState(false);
    const [offerAssets, setOfferAssets] = useState([])
    const [offerConditions, setOfferConditions] = useState<string>()
    const [productsLoading, setProductsLoading] = useState<boolean>(false)
    const [products, setProducts] = useState<OfferProduct[]>([{} as OfferProduct])

    useEffect(() => {
        props.actions.fetchOfferFields()
        // props.actions.getAllClients(3) //the static 3 is for moduleId
        props.adminActions.getOfferConditions()
    }, [])

    useEffect(()=>{
        props.actions.getAllProjectInfo('',projectId)
    },[projectId])

    useEffect(() => {
        if (Object.keys(props.permissions).length !== 0 && !checkIfUserIsAuthorized(props.permissions, props.permissions?.fullPrivileges, props.permissions?.offers)) {
            toast.error(translatedText({
                id: "app.yourNotAuthenticatedToModifyDataForThisModule",
                defaultMessage: "Nuk keni qasje ne modifikimin e te dhenave te ketij moduli!"
            }))
            props.history.push('/dashboard/offer')
        }
    }, [props.permissions])

    useEffect(() => {
        setOfferConditions(props.admin?.offerConditions?.conditions)
    }, [props.admin.offerConditions])

    useEffect(() => {
        let tempArray = [...props?.allOfferFields]
        tempArray.forEach((offerField, index)=>{
            tempArray[index] = {
                ...offerField
            }
        })
        setAllOfferFields(tempArray)
    }, [props?.allOfferFields])

    useEffect(() => {
        let tempArrayOfClients = props.allClients

        tempArrayOfClients = tempArrayOfClients.filter(val => val.name.includes(searchedClient?.charAt(0).toUpperCase()))

        setClients(tempArrayOfClients)

    }, [searchedClient])

    useEffect(() => {
        getProductsOfClient()
    }, [props.client?.client])

    //
    // useEffect(() => {
    //     setClients(props.allClients)
    // }, [props.allClients])

    useEffect(() => {
        getModuleData()
    }, [offerId])

    function getProductsOfClient() {
        let tempProducts = []
        if (props.client?.client?.id) {
            setProductsLoading(true)
            axiosCaller('GET', `products/clients/${props.client?.client?.id}${props.offers?.offerClient?.id ? `?offerId=${props.offers?.offerClient?.id}` : ``}`).then((res) => {
                tempProducts = res.data.data
                console.log(tempProducts,'tempProducts')
                if (tempProducts.length !== 0) {
                    setProducts(tempProducts)
                } else {
                    setProducts(
                        [{
                            designation: {
                                label: '',
                                value: 0
                            },
                            discount: 0,
                            price: 0,
                            tax: 0,
                            taxPrice: 0,
                            type: '',
                            unit: 0,
                            value: 0
                        }]
                    )
                }
                setProductsLoading(false)
            }).catch(() => setProductsLoading(false));
        }
    }

    function getModuleData(){
        setLoading(true)
        if (offerId) {
            props.offerActions.fetchClientOffer(offerId).then((res) => {
                props.clientActions.fetchClient(res?.data?.clientId, 3).then((res) => {//Static 3 parameter is for moduleId
                    setFirstAndLastName(`${res.data?.firstnameAndLastname} ${res.data?.lastName}`)
                })
                console.log(res,'asd')
                props.offerActions.setDiscountValue (res.data?.discountValue)
                props.offerActions.setDiscountType({value: res.data?.discountType})
                setOfferStatus(res.data?.offerStatus)
                setAddition(res.data?.addition)
                setAllOfferFields(res.data?.dynamicFields)
                setLoading(false)
                let tempArray = []
                res?.data?.offerFiles?.forEach((file)=>{
                    if(file?.finalOfferWithUrl){
                        tempArray.push({id: file.id, fileUrl: file?.finalOfferWithUrl, name: file?.finalOfferName})
                    }else if(file?.pdfOfferWithUrl){
                        tempArray.push({id: file.id, fileUrl: file?.pdfOfferWithUrl, name: file?.pdfOfferName})
                    }else if(file?.requirementWithUrl){
                        tempArray.push({id: file.id, fileUrl: file?.requirementWithUrl, name: file?.requirementName})
                    }
                })
                setOfferAssets(tempArray)
            })
        } else {
            resetFields()
            setLoading(false)
        }
    }

    function offersForm() {
        return (
            <div className="offers-form-wrapper">
                <form className="offers-form container-fluid px-0" onSubmit={(e) => e.preventDefault()}>
                    <div className="w-100 row d-flex justify-content-between">
                        <div className={`${isMobile ? "w-100 pr-0": "col-12 "}`}>
                            <p className="d-flex small-text create-new-text">
                                <TranslatedText id="client.createOffer" defaultMessage="Krijo nje oferte"/>
                                <Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm"
                                                                                            defaultMessage="Pastro Formen"/></span>}>
                                    <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}
                                         alt="create offer"/>
                                </Tooltip>
                            </p>
                            <div className="col-12 position-relative">
                                {props.client?.client?.id &&
                                    <div><a onClick={() => setIsDropDownInfoOpen(!isDropDownInfoOpen)}>
                                        <Input
                                            value={firstAndLastName || ''}
                                            type="text"
                                            inputWrapperClass="w-100 nameSearch mb-0"
                                            disabled
                                            inputTextType={EInputTextType.SimpleInput}
                                            placeholder={translatedText({
                                                id: "client.nameSurname",
                                                defaultMessage: "Emri dhe mbiemri"
                                            })}>
                                            <img className="arrowdown" src={arrowDown} alt=""/>
                                        </Input>
                                    </a></div>}
                                {isDropDownInfoOpen &&
                                <DataTransferComponent clientId={props.client?.client?.id} measureNeeded/>}
                            </div>
                        </div>
                    </div>
                    {additionalOfferInputs()}
                    <div className="w-100 row d-flex justify-content-between">
                        <div className="col-12">
                            <div className="row d-flex justify-content-end my-3">
                                {props.client?.client?.id && <div className={`${isMobile? 'col-12 hugePaddingBottom' : 'col-3'}`}>
                                    {((!props.permissions.observer) || props.permissions.fullPrivileges) && <Button
                                        isLoading={props?.buttonLoader}
                                        isDisabled={props?.buttonLoader}
                                        submit={true}
                                        className="m-auto w-100"
                                        onClick={saveOfferData}
                                        buttonType={EButtonType.GreyButton}>
                                        {props.offers.offerClient?.id ?
                                            <TranslatedText id="app.navigation.next" defaultMessage="Ruaj oferten"/> :
                                            <TranslatedText id="app.navigation.next"
                                                            defaultMessage="Proceso oferten"/>
                                        }
                                    </Button>}
                                </div>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    const resetFields = () => {
        resetClient({
            id: undefined
        })
        resetOfferClient()
        setOfferStatus({
            label: '',
            value: undefined
        })
        setOfferAssets([])
        setAddition('')
        setAllOfferFields([])
        setFirstAndLastName('')
        setIsDropDownInfoOpen(false)
        props.history.push('/dashboard/offers/create')
    }

    const offerStatusOptions = OFFER_STATUSES;

    function saveDynamicOfferFields(name: string, inputElement: React.ChangeEvent<HTMLInputElement>, index: number) {
        let tempArrayOfOfferFields = [...allOfferFields]
        tempArrayOfOfferFields[index] = {
            ...tempArrayOfOfferFields[index],
            value: inputElement.target.value
        }
        setAllOfferFields(tempArrayOfOfferFields)
    }

    function removeFile(fileId){
        setLoading(true)
        props.actions.removeFile('offer', fileId).then(()=>{
            getModuleData()
        })
    }

    function additionalOfferInputs() {
        return (
            <div className="offer-status">
                <div className={"col-12 d-flex justify-content-between additional-offers-form pr-15"}>
                    <div className='input-container d-flex justify-content-between w-100'>
                        <div className="offerStatusContainer">
                            <p className="small-text text-uppercase offer-title mb-0">
                                <TranslatedText id="offer.offerStatus" defaultMessage="Statusi i ofertes"/>
                            </p>
                            <div className="mx-0">
                                <MikaSelect
                                    placeholder={translatedText({
                                        id: "client.selectOfferStatus",
                                        defaultMessage: "Selekto statusin e ofertes"
                                    })}
                                    options={offerStatusOptions}
                                    value={offerStatus}
                                    setEntireOptionFunction={setOfferStatus}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dynamic-offer-fields-container">
                    {allOfferFields && allOfferFields?.map((offerField, index) => {
                        return (
                            <div key={index} className="offerField">
                                <p className="small-text offer-title mb-0">
                                    {offerField?.name}
                                </p>
                                <input name={offerField?.name} value={offerField?.value} type="text"
                                       onChange={(inputElement: React.ChangeEvent<HTMLInputElement>) => saveDynamicOfferFields(offerField.name, inputElement, index)}/>
                            </div>
                        )
                    })}
                </div>
                <div className="row pr-15">
                    <Input
                        name="comments"
                        label="label"
                        id="comments"
                        defaultValue=''
                        inputWrapperClass={"w-100 textarea-wrapper"}
                        type="textarea"
                        value={addition || ''}
                        onChange={(name, value) => setAddition(value)}
                    >
                        <TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="SHTESE"/>
                    </Input>
                </div>
                {props.client?.client?.id && <div className="actionsContainer">
                    <div className={'modalButton_files-container'}>
                        <div className={`d-flex ${isMobile ? 'flex-column' : ''}`}>
                            <Button
                                isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                                onClick={() => setDeleteModal(true)}
                                submit={true}
                                className={`ml-0 calculateOfferModalButton ${isMobile ? "w-100":"w-50"} me-2 align-items-center text-left`}
                                buttonType={EButtonType.BasicLink}>
                                <TranslatedText id="offer.createOffer" defaultMessage="Krijo Oferten"/>
                                <FontAwesome
                                    name="calculator"
                                    className="me-4"
                                />
                            </Button>
                            <MikaFileInput labelKey={translatedText({
                                id: "offer.addPDF",
                                defaultMessage: "Shto PDF"
                            })} multiple setFiles={setPdfFile} className={`mt-3 ${isMobile ? "w-100" : "w-50"}`} accept={".pdf"}/>
                        </div>
                        <div className={`d-flex ${isMobile ? 'flex-column' : ''}`}>
                            <MikaFileInput labelKey={translatedText({
                                id: "offer.addFinalOffer",
                                defaultMessage: "Shto oferten finale"
                            })} multiple setFiles={setFinalOfferFile} className={`mt-3 ${isMobile ? "w-100" : "w-50"}  me-2`}/>
                            <MikaFileInput labelKey={translatedText({
                                id: "offer.addRequirements",
                                defaultMessage: "Shtoni kerkesat"
                            })} multiple setFiles={setRequirementsFile} className={`mt-3 ${isMobile ? "w-100" : "w-50"}`}/>
                        </div>
                    </div>
                    <div className="files-container">
                        {offerAssets?.length > 0 &&
                        <>
                            <p className="small-text product-measure-photos">
                                <TranslatedText id="measures.productPhotos"
                                                  defaultMessage="Assetet e ofertes"/>
                            </p>
                            {offerAssets?.map((item, id) => {
                                return (
                                    <div className="d-flex align-items-center mb-2 cursor-pointer position-relative" key={id}>
                                        <img className="product-pic m-1" src={PICFile} alt="pic"/>
                                        <a target={'_blank'} href={item?.fileUrl} className="link-pics">{item?.name}</a>
                                        <div className="fileRemovalButton" onClick={() => removeFile(item?.id)}>
                                            <img src={DifferentColoredPlus} alt="close" />
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                        }
                    </div>
                </div>}
                {offerConditions && <div
                    className={"offerConditions"}
                    dangerouslySetInnerHTML={{
                        __html: offerConditions
                    }}>

                </div>}
            </div>
        )
    }

    const saveOfferData = async () => {
        // console.log(props.offers, 'asdasdasdasdasd')
        // return
        if(products?.length === 0) {
            toast.info('Ju lutem krijoni produkte per kete ofert!')
            return
        }else if(products?.length === 1 && !products[0]?.id){
            toast.info('Ju lutem krijoni produkte per kete ofert!')
            return
        }

        let payload = new FormData();
        setLoading(true)
        payload.append("id", props.offers.offerClient?.id);
        payload.append("clientId", props.client?.client?.id);
        if (offerStatus?.value) {
            payload.append("offerStatusId", offerStatus?.value);
        }
        if (props.client.client.address) {
            payload.append("address", props.client.client.address);
        }
        if (addition) {
            payload.append("addition", addition);
        }
        if (props.offers?.offerId) {
            payload.append("offerId", props.offers?.offerId);
        }
        if((props.offers?.discountType?.value == 0 || props.offers?.discountType?.value == 1) && props.offers?.discountTotal && props.offers?.discountValue){
            if(props.offers?.discountType?.value == 0 || props.offers?.discountType?.value == 1){
                payload.append("discountType", `${props.offers?.discountType?.value}` || '')
            }
            if(props.offers?.discountTotal){
                payload.append("discountTotal", `${props.offers?.discountTotal}` || '')
            }
            if(props.offers?.discountValue){
                payload.append("discountValue", props.offers?.discountValue || '')
            }
        }
        allOfferFields?.forEach((offerField, index) => {
            payload.append(`dynamicFieldIds[]`, `${offerField?.id? offerField?.id: ''}`);
            payload.append(`dynamicFieldValues[]`, offerField?.value || offerField?.value == 'undefined' ? offerField?.value : '');
        })
        if(finalOfferFile.length !== 0){
            finalOfferFile.forEach((file)=>{
                payload.append("finalOffer[]", file.file);
            })
        }
        if(requirementsFile.length !== 0){
            requirementsFile.forEach((file)=>{
                payload.append("requirement[]", file.file);
            })
        }
        if(pdfFile.length !== 0){
            pdfFile.forEach((file)=>{
                payload.append("pdfOffer[]", file.file);
            })
        }
        if (props.offers.comment) {
            payload.append("comment", props.offers.comment);
        }
        if (props.offers.offerClient?.id) {
            props.offerActions.editOffer(payload).then((res) => {
                if (res.data?.cantEdit) {
                    toast.error('Ky klient gjindet ne modulin e marrveshjes!')
                }
                props?.history?.goBack()
                setLoading(false)
            })
        } else {
            props.offerActions.createOffer(payload).then(() => {
                props?.history?.goBack()
                setLoading(false)
            })
        }
        setIsDropDownInfoOpen(false)
    }

    return (
        <div className="wrapper">
            {loading ?
                <div style={{display: "block", height: "calc(100vh - 130px)"}}>
                    <Spinner/>
                </div> :
                offersForm()
            }
            {isDeleteModalOpen && <CalculateOfferModal products={products} setProducts={setProducts} productsLoading={productsLoading} setProductsLoading={setProductsLoading} getProductsOfClient={getProductsOfClient} closeModalFunction={setDeleteModal}/>}
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        measures: state.measures,
        offers: state.offers,
        admin: state.admin
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        offerActions: bindActionCreators(offerActions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(Offers as any)));