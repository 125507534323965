import React, {useState, useEffect} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as productionActions from '../../actions/production';
import {withRouter} from 'react-router';
import FontAwesome from 'react-fontawesome'
import {Input} from '../../components/Input/Input';
import {Button, EButtonType} from '../../components/Button';
import {axiosCaller} from '../../utils/apiCaller';
import {toast} from 'react-toastify';
import {FileUpload} from "../../types/fileUpload.type";
import {BLUEPRINTING, isMobile, pauseReasonsEnum, POSTPROCESING} from "../../constants/constants";
import PlusIconLink from "../../assets/icons/plus.svg";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import HotkeyButton from "../../components/HotkeyButton/HotkeyButton";
import SimpleLoader from "../../components/Spinner/SimpleLoader";
import {SelectOption} from "../../types/selectOption.type";
import moment from "moment";
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import {ProductionFilters} from "../../types/productionFilters.type";
import {fetchProductPrices} from "../../actions/production";
import TableBbros from "../../components/Table/TableBbros";
import {OPTIONS, PRODUCTION_PRODUCTS_COLUMNTS} from "../../components/Table/TableConstants";
import MoreInfo from "@material-ui/icons/More";
import {UnitsObj} from "../../types/units.type";

interface Props {
    history: any;
    productionActions: any;
    production: any;
    userResponsibilities: any;
    intl: any;
    permissions: any
    user: any
    buttonLoader: boolean,
    departments: SelectOption[],
    actions: any
    loading: any
}

const ProductionProducts = (props: Props) => {

    const mikaSelectStatusOptions = [
        {
            value: 1,
            label: translatedText({id:'completed', defaultMessage: 'Perfunduar'})
        },
        {
            value: 2,
            label: translatedText({id:'toDo', defaultMessage: 'Per tu realizuar'})
        },
        {
            value: 9,
            label: translatedText({id:'nonActive', defaultMessage: 'Ne proces jo aktiv'})
        },
        {
            value: 3,
            label: translatedText({id:'late', defaultMessage: 'Me vonese'})
        },
        {
            value: 4,
            label: translatedText({id:'inProcess', defaultMessage: 'Ne proces'})
        },
        {
            value: 5,
            label: translatedText({id:'paused', defaultMessage: 'Pauzuar'})
        },
        {
            value: 6,
            label: translatedText({id:'endOfShift', defaultMessage: 'Perfundimi i orarit'})
        },
        {
            value: 7,
            label: translatedText({id:'otherResponsibility', defaultMessage: 'Kalim ne punen tjeter'})
        },
        {
            value: 8,
            label: translatedText({id:'other', defaultMessage: 'Arsye tjeter'})
        }
    ]

    const sortOptions = [
        {
            label: 'Sipas dates se fillimit',
            value: 'start'
        },
        {
            label: 'Sipas dates se mbarimit',
            value: 'end'
        }
    ]

    const filterBy = [
        {
            label: 'Sipas dates se fillimit',
            value: '1'
        },
        {
            label: 'Sipas dates se mbarimit',
            value: '2'
        }
    ]

    const currentUserId = localStorage.getItem('mikaUserId')
    const [seconds, setSeconds] = useState(0)
    const [paused, setPaused] = useState(true)
    const [productsWithStatuses, setProductsWithStatuses] = useState([])
    const [selectedResponsibilityId, setSelectedResponsibilityId] = useState<number>()
    const [selectedResponsibilityLabel, setSelectedResponsibilityLabel] = useState<string>()
    const [selectedResponsibilityUser, setSelectedResponsibilityUser] = useState<string>()
    const [selectedProductId, setSelectedProductId] = useState<number>()
    const [selectedProductStatusRow, setSelectedProductStatusRow] = useState<any>()
    const [selectedStatusId, setSelectedStatusId] = useState<number>()
    const [alreadySetStatusId, setAlreadySetStatusId] = useState<number>()
    const [selectedProductClientId, setSelectedProductClientId] = useState<number>()
    const [searchedClient, setSearchedClient] = useState<string>('')
    const [comment, setComment] = useState<string>()
    const [otherComment, setOtherComment] = useState<string>()
    const [existingComment, setExistingComment] = useState<string>()
    const [materialIds, setMaterialIds] = useState<number[]>([])
    const [calendarComment, setCalendarComment] = useState('')
    const [projectUploads, setProjectUploads] = useState<FileUpload[]>([] as FileUpload[])
    const [selectedProjectId, setSelectedProjectId] = useState(null)
    const [selectedProductIndex, setSelectedProductIndex] = useState(null)
    const [selectedMaterial, setSelectedMaterial] = useState(null)
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(null)
    const [responsibilityTimeTrackerInfo, setResponsibilityTimeTrackerInfo] = useState<any>()
    const [productionFilterOpened, setProductionFilterOpened] = useState(false)
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [materialsOfProduct, setMaterialsOfProduct] = useState([])
    const [productStartDate, setProductStartDate] = useState<any>('')
    const [productEndDate, setProductEndDate] = useState<any>('')
    const [calculatedTotalPrice, setCalculatedTotalPrice] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [productionFilters, setProductionFilters] = useState<ProductionFilters>({})


    useEffect(() => {
        props.productionActions.fetchResponsibilities()
        props.actions.getAllDepartments()
                callProductStatuses()
        // let interval = setInterval(function () {
        //     if (window.location.href.includes('dashboard/production/products')) {
        //     } else {
        //         localStorage.removeItem('mikaProductionFilters')
        //         localStorage.removeItem('mikaProductionEndDate')
        //         localStorage.removeItem('mikaProductionStartDate')
        //         localStorage.removeItem('mikaSelectedDepartmentId')
        //         clearInterval(interval)
        //     }
        // }, 7000)
        // return () => {
        //     localStorage.removeItem('mikaProductionFilters')
        //     localStorage.removeItem('mikaProductionEndDate')
        //     localStorage.removeItem('mikaProductionStartDate')
        //     localStorage.removeItem('mikaSelectedDepartmentId')
        // };
    }, [])

    function callProductStatuses() {
        props.productionActions.fetchProductPrices(localStorage?.getItem('mikaSelectedDepartmentId'), localStorage?.getItem('mikaProductionStartDate'), localStorage?.getItem('mikaProductionEndDate'), 0, localStorage?.getItem('mikaSearchClient'), JSON.parse(localStorage?.getItem('mikaProductionFilters'))?.selectedResponsibility?.id, JSON.parse(localStorage?.getItem('mikaProductionFilters'))?.selectedStatus?.value,JSON.parse(localStorage?.getItem('mikaProductionFilters'))?.selectedSort?.value, JSON.parse(localStorage?.getItem('mikaProductionFilters'))?.selectedFilterDatesBy?.value, JSON.parse(localStorage?.getItem('mikaProductionFilters'))?.measureStatus?.value)
        if (localStorage?.getItem('mikaSelectedProductId') || selectedProductId) {
            props?.productionActions?.getMaterialsOfProduct(selectedProductId ? selectedProductId : localStorage?.getItem('mikaSelectedProductId'))
        }
    }

    useEffect(() => {
        if (projectUploads.length !== 0) {
            let payload = new FormData()

            projectUploads?.forEach((upload) => {
                payload.append('blueprint[]', upload.file)
                payload.append('projectId', JSON.stringify(selectedProjectId))
                payload.append('productId', JSON.stringify(selectedProductId))
            })

            props.productionActions.createBlueprint(payload).then(() => {
                props.productionActions?.getBlueprintFiles(selectedProductId)
            })
        }
    }, [projectUploads])

    useEffect(() => {
        if (seconds) {
            if ((!paused) && seconds && selectedResponsibilityId) {
                setTimeout(() => {
                    if ((!paused) && seconds && selectedResponsibilityId) {
                        let tempSeconds = seconds
                        if (!paused) {
                            setSeconds(tempSeconds + 1)
                        }
                    } else {
                        setSeconds(0)
                    }
                }, 1000)
            } else {
                if (selectedStatusId === 4) {
                    setSeconds(0)
                }
            }
        }
    }, [seconds, paused])

    useEffect(() => {
        if (!searchedClient) {
            localStorage.removeItem('mikaSearchClient')
        }
    }, [searchedClient])

    function convertDateForIos(date) {
        let arr = date?.split(/[- :]/);
        let convertedDate = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
        return convertedDate;
    }

    useEffect(() => {
        let tempProductsStatusArray = []
        let tempCalcTotalPrice = 0
        let statusRowData = {
            productId: null,
            clientName: '',
            productName: '',
            statuses: [],
            clientId: '',
            start: '',
            end: '',
            projectName: '',
            projectId: null,
            measureDone: '',
            // responsibilities: [],
            quantity: '',
            unit: '',
            measureUnit: '',
            // allMaterialStatuses: [],
            calendarComment: '',
            productCode: '',
            totalPrice: null
        }

        if (props.production.productPrices?.length !== 0) {
            props.production.productPrices?.forEach((productStatus) => {
                let tempResponsibilities = []
                productStatus.statuses?.forEach((status) => {
                    tempResponsibilities?.push({
                        id: status?.responsibility?.id,
                        label: status?.responsibility?.label
                    })
                })
                statusRowData = {
                    ...statusRowData,
                    clientId: productStatus?.client?.id,
                    productId: productStatus?.id,
                    clientName: productStatus?.client?.name,
                    productName: productStatus?.designation?.name,
                    statuses: productStatus?.statuses,
                    // responsibilities: tempResponsibilities,
                    start: productStatus?.start,
                    calendarComment: productStatus?.calendarComment,
                    end: productStatus?.end,
                    projectName: productStatus?.project?.name,
                    projectId: productStatus?.project?.id,
                    quantity: `${parseFloat(productStatus?.quantity)?.toFixed(2)}${productStatus?.designation?.unit?.name}`,
                    measureUnit: productStatus?.designation?.unit?.name,
                    measureDone: productStatus?.measureStatus === 2 ? "Matje finale" : "Matje fillestare",
                    productCode: productStatus?.type,
                    totalPrice: productStatus?.discountedPrice ? productStatus?.discountedPrice : productStatus?.totalPrice,
                    // allMaterialStatuses: productStatus?.materialStatuses || []
                }
                tempCalcTotalPrice += +(productStatus?.discountedPrice ? productStatus?.discountedPrice : productStatus?.totalPrice)
                tempProductsStatusArray.push(statusRowData)
            })
        }
        setCalculatedTotalPrice(tempCalcTotalPrice)
        setProductsWithStatuses(tempProductsStatusArray)
    }, [props.production.productPrices])


    useEffect(() => {
        let tempArray = []
        props.production?.productMaterials?.forEach((material) => {
            if (material?.isDone) {
                tempArray.push(JSON.stringify(material?.value))
            }
        })
        setMaterialIds(tempArray)
    }, [props.production.productMaterials])

    useEffect(() => {
        if (props?.production?.productMaterials?.length !== 0) {
            if (materialIds.length === props.production?.productMaterials?.length) {
                setSelectedStatusId(1)
            }
        }
    }, [materialIds])

    useEffect(() => {
        setLoading(true)
        localStorage.setItem('mikaSelectedDepartmentId', selectedDepartmentId)
        props.productionActions.fetchProductPrices(selectedDepartmentId).then(() => {
            setSearchedClient('')
            setLoading(false)
        })
    }, [selectedDepartmentId])

    useEffect(() => {
        if(productionFilters?.selectedSort || productionFilters?.selectedResponsibility || productionFilters?.selectedStatus || (startDate && endDate)){
            if(startDate && endDate){
                let startDateTime = new Date(startDate)?.getTime()
                let endDateTime = new Date(endDate)?.getTime()

                if (startDateTime > endDateTime) {
                    localStorage.removeItem('mikaProductionStartDate')
                    localStorage.removeItem('mikaProductionEndDate')
                    toast.error('Data e fillimit duhet te jete para dates se mbarimit!')
                }else{
                    localStorage.setItem('mikaProductionEndDate', endDate)
                    localStorage.setItem('mikaProductionStartDate', startDate)
                    callProductStatuses()
                }
            }else{
                localStorage.removeItem('mikaProductionStartDate')
                localStorage.removeItem('mikaProductionEndDate')
                callProductStatuses()
            }
        }else{
            localStorage.removeItem('mikaProductionStartDate')
            localStorage.removeItem('mikaProductionEndDate')

        }
    }, [startDate, endDate, productionFilters])

    function filterProducts() {
        props.productionActions.fetchProductPrices(localStorage?.getItem('mikaSelectedDepartmentId'), startDate, endDate, 0, searchedClient)
    }

    function allChecksResponsibilityOnClick(productId: number, responsibilityId: number, clientId: number, statusId: number, productStatuses, responsibilityLabel, productIndex, projectId, allMaterialStatuses?) {
        let isValid: any = true
        if (!checkIfUserHasThatResponsibility(responsibilityId)) {
            isValid = false
        }

        let status = productStatuses?.find(item => item.responsibility?.id === responsibilityId)
        if (!props.permissions?.fullPrivileges) {
            if (status.responsibility.userId && (currentUserId != status.responsibility.userId)) {
                isValid = true
            } else {
                // if(!checkIfPreviousProductIsDone(responsibilityId, productIndex)){
                //     isValid = false
                // }
            }
            // if(checkPreviousResponsibilityStatusId(responsibilityId, productIndex) === 4 || checkPreviousResponsibilityStatusId(responsibilityId, productIndex) === 9){
            //     // isValid = true
            //     console.log(productsWithStatuses[productIndex],checkPreviousResponsibilityStatusId(responsibilityId, productIndex), responsibilityLabel, 'lek')
            //     isValid = true
            // }

            if (status?.responsibilityStatusId === 4 || status?.responsibilityStatusId === 9) {
                return true
            }
            if ((status.responsibility.userId) && status?.responsibilityStatusId === 4) {
                isValid = false
            }
        }

        if (!props.permissions?.fullPrivileges && (status?.responsibilityStatusId === 1)) {
            isValid = false
        }

        let indexOfStatusBox = productStatuses.indexOf(status)
        if (productStatuses.indexOf(status) !== 0) {
            if (productStatuses[indexOfStatusBox - 1]?.responsibilityStatusId !== 1 && productStatuses[indexOfStatusBox - 1]?.responsibilityStatusId !== 4 && productStatuses[indexOfStatusBox - 1]?.responsibilityStatusId !== 9) {
                isValid = false
            }
        }


        // if (!isValid) {
        //     if (checkIfResponsibilityHasClickableMaterial(responsibilityId, responsibilityLabel, allMaterialStatuses, productStatuses, indexOfStatusBox)) {
        //         isValid = {
        //             hasClickableMaterials: true
        //         }
        //     }
        // }
        return isValid
    }

    function checkIfUserHasThatResponsibility(responsibilityId) {
        const hasResponsibility = props.userResponsibilities?.includes(responsibilityId);
        if (hasResponsibility || props.permissions?.fullPrivileges) {
            return true;
        } else {
            return false;
        }
    }

    function checkIfMaterialPreviousProcessIsDone(foundMaterialInPreviousProcess) {
        return foundMaterialInPreviousProcess?.responsibilityStatusId === 1;
    }

    function deleteBlueprintFile(fileId) {
        props.productionActions.deleteBlueprintFile(fileId).then(() => {
            props.productionActions?.getBlueprintFiles(selectedProductId)
        })
    }


    useEffect(() => {
        const groupByResponsibilityName = props?.production?.materialsOfProduct.reduce((group, materials) => {
            const {responsibilityName} = materials;
            group[responsibilityName] = group[responsibilityName] ?? [];
            group[responsibilityName].push(materials);
            return group;
        }, {});
        setMaterialsOfProduct(groupByResponsibilityName)
    }, [props?.production?.materialsOfProduct])

    function openSelectedProductModal(productData) {
        props.productionActions?.getBlueprintFiles(productData?.productId)
        setSelectedProductStatusRow(productData)
    }

    function closeSelectedProductModal() {
        setSelectedProductStatusRow(undefined)
        setProductEndDate('')
        setProductStartDate('')
        setCalendarComment('')
    }

    function saveProductDates() {
        if (productStartDate || productEndDate) {
            if (!calendarComment) {
                toast.info('Ju lutem vendosni nje koment per ndryshime te datave!')
                return;
            }
        }

        let productStartDateMs = new Date(productStartDate)?.getTime()
        let productEndDateMs = new Date(productEndDate)?.getTime()
        if (productStartDateMs > productEndDateMs) {
            toast.error('Data e mbarimit smund te jete para fillimit!')
            return;
        }

        if ((productStartDate || selectedProductStatusRow?.start) && productEndDate) {
            if (new Date(productStartDate).getTime() + 86400000 < new Date()?.getTime()) {
                toast.info('Data e fillimit duhet te jete ne te ardhmen!')
                return
            }

            let payload = {
                productId: Number(selectedProductStatusRow?.productId),
                start: productStartDate ? moment(productStartDate).format('YYYY-MM-DD') : moment(selectedProductStatusRow?.start).format('YYYY-MM-DD'),
                end: moment(productEndDate).format('YYYY-MM-DD'),
                calendarComment: calendarComment
            }

            props.productionActions.updateProductDates(payload).then((res) => {
                if (res?.data?.data) {
                    props.productionActions.fetchProductPrices(selectedDepartmentId ? selectedDepartmentId : localStorage?.getItem('mikaSelectedDepartmentId'), startDate, endDate, 0, searchedClient ? searchedClient : localStorage?.getItem('mikaSearchClient')).then(() => {
                        toast.success('Data eshte edituar')
                        closeSelectedProductModal()
                    })
                } else {
                    toast.error('Keto data jane te zena!')
                }
            })
        } else {
            toast.info('Ju lutem mbushni fushat e datave!')
        }
    }

    function setProductionFiltersToLocalStorage(key, value){
        let tempProductionFilters = {
            ...productionFilters,
            [key]: value[key]
        }

        if(value[key]?.value === null){
            delete tempProductionFilters[key]
        }
        localStorage.setItem('mikaProductionFilters', JSON?.stringify(tempProductionFilters))

        setProductionFilters(tempProductionFilters)
    }

    function convertFileToBase64(fileUrl, callback) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', fileUrl, true);
        xhr.responseType = 'blob';

        xhr.onload = () => {
            const reader = new FileReader();
            reader.onloadend = () => {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };

        xhr.send();
    }

    const actions = [
        {
            icon: MoreInfo,
            tooltip: 'Info',
            onClick: (event: Event, rowData: any) => openSelectedProductModal(rowData)
        }
    ]

    return (
        <div className="product-statuses-container">
            <div className={'mobileAlign'}>
                {/*<p className="title">*/}
                {/*    <TranslatedText id="production.responsibilityAssigment" defaultMessage="Kontrolli i proceseve"/>*/}
                {/*</p>*/}
                <div className="searchByClientContainer">
                    <input className={'searchBar'} type="text" placeholder={'Kerko sipas klientit!'}
                           value={searchedClient} onChange={(e) => {
                        setSearchedClient(e.target.value)
                        setSelectedProductIndex(null)
                        localStorage.setItem('mikaSearchClient', e.target.value)
                    }} onKeyDown={(e) => e.key === 'Enter' ? filterProducts() : ''}/>
                    <div className={'searchIcon'} onClick={filterProducts}>
                        <FontAwesome name={'search'}/>
                    </div>
                </div>

            </div>
            <div className={'departmentFilterContainer'}>
                <div className={`departmentFilter ${selectedDepartmentId === null ? 'active' : ''}`}
                     onClick={() => setSelectedDepartmentId(null)}>
                    <p><TranslatedText id="all" defaultMessage="Te gjitha"/></p>
                </div>
                {props?.departments?.map((department) => {
                    return (<div
                        className={`departmentFilter ${selectedDepartmentId === department?.value ? 'active' : ''}`}
                        onClick={() => setSelectedDepartmentId(department?.value)}>
                        <p>{department?.label}</p>
                    </div>)
                })}
            </div>
            <div className="status-table productionsProductsTable w-100">
                <div className={'totalPriceContainerForProduction calcproductionProducts'}>
                    <p><TranslatedText id="total"
                                                       defaultMessage="Totali: "/></p>
                    <p>{calculatedTotalPrice?.toFixed(2)} €</p>
                </div>
                {/*<div className="status-table-header">*/}
                {/*    <div className="header-title col-margin checkbox">*/}
                {/*    </div>*/}
                {/*    <div className="header-title col-margin client productionProducts">*/}
                {/*        <p className="m-0"><TranslatedText id="production.responsibilityAssigment"*/}
                {/*                                           defaultMessage="Klienti"/></p>*/}
                {/*    </div>*/}
                {/*    <div className="header-title col-margin product productionProducts">*/}
                {/*        <p className="m-0"><TranslatedText id="production.responsibilityAssigment"*/}
                {/*                                           defaultMessage="Produkti"/></p>*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*        className={`header-title col-margin product productionProducts ${props?.permissions?.fullPrivileges ? 'admin' : 'notAdmin'}`}>*/}
                {/*        <p className="m-0"><TranslatedText id="production.responsibilityAssigment"*/}
                {/*                                           defaultMessage="Projekti"/></p>*/}
                {/*    </div>*/}
                {/*    */}
                {/*</div>*/}
                <div>
                    <div className={'productionProductsFilter'}>
                        <div className={'productionFilterIconContainer'}>
                            <HotkeyButton
                                keyCode="Escape"
                                modifiers={[]}
                                isActive={false}
                                onStateChange={(state) => {
                                    if (state) {
                                        setProductionFilterOpened(false)
                                    }
                                }}
                            >
                                <div className={'productionFilterIcon'}
                                     onClick={() => setProductionFilterOpened(!productionFilterOpened)}>
                                    <FontAwesome
                                        name={'filter'}
                                    />
                                </div>
                            </HotkeyButton>
                            <div
                                className={`productionFilterWrappers productionProductsFilter ${productionFilterOpened ? 'opened' : 'hidden'}`}>
                                <div
                                    className={`productionFilter`}>
                                    <MikaSelect options={filterBy} placeholder={'Filtrimi sipas:'}
                                                value={productionFilters?.selectedFilterDatesBy}
                                                setEntireOptionFunction={(value) => setProductionFiltersToLocalStorage('selectedFilterDatesBy', {
                                                    selectedFilterDatesBy: value
                                                })}/>
                                </div>
                                <div
                                    className={`productionFilter`}>
                                    <MikaSelect
                                        options={[{value: 1, label: "Matje fillestare"}, {value: 2, label: "Matje finale"}]}
                                        placeholder={'Matjet:'}
                                        value={productionFilters?.measureStatus}
                                        setEntireOptionFunction={(value) => setProductionFiltersToLocalStorage('measureStatus', {
                                            measureStatus: value
                                        })}/>
                                </div>
                                <div
                                    className={`productionFilter dateContainer`}>
                                    <p className={'label'}>{<TranslatedText id="startDate"
                                                                            defaultMessage={'Data e fillimit'}/>}</p>
                                    <p className={'dateValue'}>{startDate ? moment(startDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                    {startDate &&
                                        <img src={DifferentColoredPlus} onClick={() => setStartDate('')}
                                             className="x-signDate"/>}
                                    <div className={'calendar'}>
                                        <input type="date" value={startDate}
                                               onChange={(e) => setStartDate(e?.target?.value)}/>
                                        <div className={'calendarIcon'}>
                                            <FontAwesome
                                                name="calendar"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`productionFilter dateContainer`}>
                                    <p className={'label'}>{<TranslatedText id="endDate"
                                                                            defaultMessage={'Data e mbarimit'}/>}</p>
                                    <p className={'dateValue'}>{endDate ? moment(endDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                    {endDate &&
                                        <img src={DifferentColoredPlus} onClick={() => setEndDate('')}
                                             className="x-signDate"/>}
                                    <div className={'calendar'}>
                                        <input type="date" value={endDate}
                                               onChange={(e) => setEndDate(e?.target?.value)}/>
                                        <div className={'calendarIcon'}>
                                            <FontAwesome
                                                name="calendar"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`productionFilter`}>
                                    <MikaSelect options={sortOptions} placeholder={'Renditja'}
                                                value={productionFilters?.selectedSort}
                                                setEntireOptionFunction={(value) => setProductionFiltersToLocalStorage('selectedSort', {
                                                    selectedSort: value
                                                })}/>
                                </div>
                                <div>
                                    <Button
                                        isLoading={props?.buttonLoader}
                                        className={`w-100`}
                                        onClick={(e) => callProductStatuses()}
                                        buttonType={EButtonType.GreyButton}>
                                        <TranslatedText id="filter" defaultMessage="Filtro"/>
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <TableBbros
                        className={'containsOneActions productionProducts'}
                        title={translatedText({id: "table.responsibility", defaultMessage: "Produktet ne prodhim"})}
                        options={OPTIONS}
                        actions={actions}
                        isLoading={props.loading}
                        columns={PRODUCTION_PRODUCTS_COLUMNTS}
                        data={productsWithStatuses}/>
                </div>
                {/*<>*/}
                {/*    {loading ? <SimpleLoader/> : <>*/}
                {/*        {productsWithStatuses && <div className="status-table-content">*/}
                {/*            {productsWithStatuses?.length !== 0 ?*/}
                {/*                <>*/}
                {/*                    {productsWithStatuses?.map((product, index) => {*/}
                {/*                        if (product?.statuses?.length === 0) {*/}
                {/*                            return*/}
                {/*                        }*/}
                {/*                        return (*/}
                {/*                            <>*/}

                {/*                                <div*/}
                {/*                                    // className={`status-row ${product?.measureDone ? '' : 'opacityLow'} ${checkIfRowNeededToBeRemoved(product) ? 'd-none' : ''} ${checkIfRowNeedsToBeRemovedForSimpleUsers(product, index) ? 'd-none' : ''}`}*/}
                {/*                                    className={`status-row`}*/}
                {/*                                    id={`row${index}`}*/}
                {/*                                    key={`${Math.random()}${product.id}${index}${product.id}`}>*/}
                {/*                                    <div*/}
                {/*                                        className={`client-name productionProducts checkbox col-margin ${props?.permissions?.fullPrivileges ? '' : 'd-none'}`}>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="client-name productionProducts col-margin nameContainer"*/}
                {/*                                         data-toggle="tooltip"*/}
                {/*                                         data-placement="top" title={product.clientName}>*/}
                {/*                                        <p className="m-0"*/}
                {/*                                           onClick={() => openSelectedProductModal(product)}>{product?.clientName}</p>*/}
                {/*                                    </div>*/}
                {/*                                    <div className="product-name productionProducts col-margin quantityWrapper"*/}
                {/*                                         data-toggle="tooltip"*/}
                {/*                                         data-placement="top" title={product?.productName}>*/}
                {/*                                        <p className="m-0"*/}
                {/*                                           onClick={() => openSelectedProductModal(product)}>{product?.productName}({product?.productCode})</p>*/}
                {/*                                        {product?.quantity && <p className={'quantityContainer'}>{parseFloat(product?.quantity)?.toFixed(2)} {product?.unit}</p>}*/}
                {/*                                    </div>*/}
                {/*                                    <div*/}
                {/*                                        className={`product-name project-name productionProducts col-margin ${props?.permissions?.fullPrivileges ? 'admin' : 'notAdmin'}`}*/}
                {/*                                        data-toggle="tooltip" data-placement="top"*/}
                {/*                                        title={product?.projectName}>*/}
                {/*                                        <p className="m-0">{product?.projectName}</p>*/}
                {/*                                    </div>*/}
                {/*                                    <div*/}
                {/*                                        className={`product-name project-name productionProducts col-margin`}*/}
                {/*                                        data-toggle="tooltip" data-placement="top"*/}
                {/*                                        title={product?.projectName}>*/}
                {/*                                        <p className="m-0">{product?.totalPrice}€</p>*/}
                {/*                                    </div>*/}
                {/*                                    <div*/}
                {/*                                        className={`product-name project-name productionProducts col-margin`}*/}
                {/*                                        data-toggle="tooltip" data-placement="top"*/}
                {/*                                        title={product?.projectName}>*/}
                {/*                                        <p className="m-0">{moment(product?.start)?.format('DD/MM/YYYY')}</p>*/}
                {/*                                    </div>*/}
                {/*                                    <div*/}
                {/*                                        className={`product-name project-name productionProducts col-margin`}*/}
                {/*                                        data-toggle="tooltip" data-placement="top"*/}
                {/*                                        title={product?.projectName}>*/}
                {/*                                        <p className="m-0">{moment(product?.end)?.format('DD/MM/YYYY')}</p>*/}
                {/*                                    </div>*/}
                {/*                                    {(!product?.measureDone) && <div*/}
                {/*                                        className={``}*/}
                {/*                                        data-toggle="tooltip" data-placement="top"*/}
                {/*                                        title={product?.projectName}>*/}
                {/*                                        <p className="m-0 measureNotDoneLabel"><TranslatedText id="measureIsNotFinal"*/}
                {/*                                                                                               defaultMessage={'Matja nuk eshte finale'}/></p>*/}
                {/*                                    </div>}*/}
                {/*                                </div>*/}

                {/*                            </>*/}
                {/*                        )*/}
                {/*                    })}*/}
                {/*                </>*/}
                {/*                :*/}
                {/*                <p className={'m-0 text-center'}><TranslatedText id="app.noData"*/}
                {/*                                                                 defaultMessage="Nuk ka te dhena !"/>*/}
                {/*                </p>*/}
                {/*            }*/}
                {/*        </div>}*/}
                {/*    </>}*/}

                {/*</>*/}
            </div>
            {selectedProductStatusRow && <div className="update-status-modal-container selectedProductStatusRow">
                <div className="update-status-modal">
                    <HotkeyButton
                        keyCode="Escape"
                        modifiers={[]}
                        isActive={false}
                        onStateChange={(state) => {
                            if (state) {
                                closeSelectedProductModal()
                            }
                        }}
                    >
                        <div className={'x-sign'}>
                            <img src={DifferentColoredPlus} alt="" onClick={() => {
                                closeSelectedProductModal()
                            }}/>
                        </div>
                    </HotkeyButton>
                    <div className={'selectedProductStatusRowData'}>
                        <p><span>{<TranslatedText id="name"
                                                  defaultMessage={'Emri'}/>}:</span> {selectedProductStatusRow?.clientName}
                        </p>
                        <p><span>{<TranslatedText id="product"
                                                  defaultMessage={'Produkti'}/>}:</span> {selectedProductStatusRow?.productName} ( {selectedProductStatusRow?.productCode} )
                        </p>
                        <p><span>{<TranslatedText id="project"
                                                  defaultMessage={'Projekti'}/>}:</span> {selectedProductStatusRow?.projectName}
                        </p>

                        {props?.permissions?.fullPrivileges ?
                            <div className={'d-flex justify-content-between align-items-center'}>
                                <Input
                                    name="date"
                                    value={productStartDate ? new Date(productStartDate) || '' : new Date(selectedProductStatusRow?.start) || ''}
                                    type="date"
                                    onChange={(name, value) => setProductStartDate(value)}
                                    inputWrapperClass={`simple-date-input mt-0 heightFitContent ${productStartDate ? " " : 'does-not-have-data'}`}>
                                    <TranslatedText id="client.scanDocument" defaultMessage="Data e fillimit *"/>
                                </Input>
                                <Input
                                    name="date"
                                    value={productEndDate ? new Date(productEndDate) || '' : new Date(selectedProductStatusRow?.end) || ''}
                                    type="date"
                                    onChange={(name, value) => setProductEndDate(value)}
                                    inputWrapperClass={`simple-date-input mt-0 heightFitContent ${productEndDate ? " " : 'does-not-have-data'}`}>
                                    <TranslatedText id="client.scanDocument" defaultMessage="Data e mbarimit *"/>
                                </Input>
                            </div> :
                            <>
                                <p>
                                    <span>{<TranslatedText id="startDate"
                                                           defaultMessage={''}/>}:</span> {moment(selectedProductStatusRow?.start).format('DD-MM-YYYY')}
                                </p>
                                <p>
                                    <span>{<TranslatedText id="endDate"
                                                           defaultMessage={''}/>}:</span> {moment(selectedProductStatusRow?.end).format('DD-MM-YYYY')}
                                </p>
                            </>}
                        <div>
                            <Input
                                name="comments"
                                label="label"
                                id="comments"
                                defaultValue=''
                                inputWrapperClass={"w-100 m-0 textarea-wrapper"}
                                type="textarea"
                                value={calendarComment ? calendarComment || '' : selectedProductStatusRow?.calendarComment}
                                onChange={(name, value) => setCalendarComment(value)}
                            >
                                <TranslatedText id="app.agreements.appendFinalAgreement"
                                                defaultMessage="Koment per ndyshim te datave"/>
                            </Input>
                        </div>
                        <div className={`d-flex ${isMobile ? 'flex-column' : ''}`}>
                            <div className={`${isMobile ? 'w-100' : 'w-50 '} allFileContainer`}>
                                {props.production?.blueprintFiles?.length !== 0 && props.production?.blueprintFiles?.map((projectUpload) => {
                                    let tempBase64Text = ''
                                    convertFileToBase64(projectUpload?.blueprint,(base64Data)=>{
                                        tempBase64Text = base64Data
                                    })
                                    return (
                                        <div className={'blueprint-file-container'}>
                                            <a target={'_blank'}
                                               href={projectUpload?.blueprint}>{projectUpload?.nameOfBlueprint}</a>
                                            <a href={tempBase64Text} target={'_blank'} className={'downloadButton'} download={projectUpload?.nameOfBlueprint}>
                                                <FontAwesome name={'download'}/>
                                            </a>
                                            {(selectedResponsibilityLabel === BLUEPRINTING || selectedResponsibilityLabel === POSTPROCESING || props?.permissions?.fullPrivileges) &&
                                            <div className={'deleteButton'}
                                                 onClick={() => deleteBlueprintFile(projectUpload?.id)}>
                                                <img src={PlusIconLink} alt="plus icon link"/>
                                            </div>}
                                        </div>
                                    )
                                })}
                            </div>
                            {props?.permissions?.fullPrivileges && <div
                                className={`d-flex justify-content-end align-items-end ${isMobile ? 'w-100' : 'w-50'}`}>
                                {((!props.permissions.observer) || props.permissions.fullPrivileges) && <Button
                                    isLoading={props?.buttonLoader}
                                    className={`my-4 ${isMobile ? 'w-100' : 'w-50'}`}
                                    onClick={(e) => saveProductDates()}
                                    buttonType={EButtonType.GreyButton}>
                                    <TranslatedText id="app.save" defaultMessage="Ruaj datat"/>
                                </Button>}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
        user: state.user
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        productionActions: bindActionCreators(productionActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(ProductionProducts as any)));