import React, {useEffect, useRef, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as serviceActions from '../../actions/service';
import * as transportActions from '../../actions/transport';
import * as installationActions from '../../actions/installation';
import "./Service.scss";
import {withRouter} from 'react-router';
import {User} from '../../types/user.type';
import Spinner from '../../components/Spinner';
import {ArticleInfomation} from '../../types/ArticleInformation.type';
import {SelectOption} from '../../types/selectOption.type';
import {EInputTextType, Input} from "../../components/Input/Input";
import {Button, EButtonType} from "../../components/Button";
import {useParams} from "react-router-dom";
import {ParameterTypes} from "../../types/parameter.type";
import Select from "react-select";
import Tooltip from "rc-tooltip";
import clearFormSvg from "../../assets/icons/archeology.svg";
import {toast} from "react-toastify";
import {isMobile, SERVICE_STATUSES} from "../../constants/constants";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import {
    OPTIONS, SERVICE_PROJECTS,
} from "../../components/Table/TableConstants";
import TableBbros from "../../components/Table/TableBbros";
import EditIcon from "@material-ui/icons/Edit";
import MikaFileInput from "../../components/Input/MikaFileInput/MikaFileInput";
import {FileUpload} from "../../types/fileUpload.type";
import PICFile from "../../assets/icons/pic.svg";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import ReactToPrint from "react-to-print";
import FileIcon from "../../assets/icons/file-text.svg";
import {MeasureImages} from "../Measures/MeasureImages";
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";
import moment from "moment";
import clock from "../../assets/icons/clock.svg";
import FontAwesome from 'react-fontawesome'
import {setSelectedProjectForService} from "../../actions/service";


interface Props {
    intl: any;
    setLoading: boolean;
    actions: any;
    clientActions: any;
    client: any;
    loading: boolean;
    articles: ArticleInfomation[];
    app: any;
    user: any;
    measureUsers: User[];
    generalActions: any;
    allClients: SelectOption[];
    countries: any[];
    citiesFields: any[];
    transport: any;
    transportActions: any;
    archiveRecords: any;
    installation: any
    installationActions: any
    history: any
    serviceActions: any
    service: any
    buttonLoader: boolean
    permissions: any
    projectInformation: any
    notRegisteredClients: boolean
}

const CreateServiceForm = (props: Props) => {

    const {serviceId} = useParams<ParameterTypes>();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [problem, setProblem] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [warning, setWarning] = useState('')
    const [projects, setProjects] = useState<any>([])
    const [projectProducts, setProjectProducts] = useState<any>([])
    const [selectedProject, setSelectedProject] = useState<any>()
    const [selectedProducts, setSelectedProducts] = useState<any>()
    const [addPhoto, setAddPhoto] = useState<FileUpload[]>([] as FileUpload[])
    const [assets, setAssets] = useState([])
    const [agreementAssets, setAgreementAssets] = useState([])
    const [installationPhotos, setInstallationPhotos] = useState([])
    const [serviceStatus, setServiceStatus] = useState<SelectOption>()
    const [startTime, setStartTime] = useState<any>()
    const [endTime, setEndTime] = useState<any>()
    const [serviceDate, setServiceDate] = useState<any>()

    const componentRef = useRef();

    useEffect(() => {
        if(props?.notRegisteredClients){
            props.actions.fetchArticles();

        }else{
            props?.serviceActions?.getProjectReadyForService()
        }
    }, [])

    useEffect(()=>{
        if(props?.projectInformation?.installation?.length !== 0){
            let tempInstallationFiles = []
            props?.projectInformation?.installation?.forEach((installation)=>{
                installation?.installationFile?.forEach((file)=>{
                    tempInstallationFiles.push({ id: file.id, fileUrl: file?.installationFileWithUrl, name: file?.installationFileName })
                })
            })
            setInstallationPhotos(tempInstallationFiles)
        }
        if(props?.projectInformation?.products?.length !== 0){
            let tempArray = []
            props?.projectInformation?.products?.forEach((product)=>{
                if(product?.id && product?.articleId){
                    tempArray.push({
                        ...product,
                        label: `${product?.name} (${product?.code})`,
                        value: product?.id
                    })
                }
            })
            setProjectProducts(tempArray)
        }
    }, [props?.projectInformation])

    useEffect(()=>{
        setSelectedProject({
            ...props?.service?.selectedProjectForService
        })
    },[props?.service?.selectedProjectForService])

    useEffect(() => {
        let tempArray = []
        props?.service?.projectsReadyForService?.forEach((project) => {
            tempArray.push({
                label: `${project?.projectName} - ${project?.client?.firstnameAndLastname} ${project?.client?.lastName}`,
                value: project?.id,
                clientId: project?.client?.id,
                firstName: project?.client?.firstnameAndLastname,
                lastName: project.client?.lastName,
                address: project?.client?.address,
                city: project?.client?.city,
                phoneNumber: project?.client?.phoneNumber,
                email: project?.client?.email,
                firstAndLastName: `${project?.client?.firstnameAndLastname} ${project?.client?.lastName}`
            })
        })

        setProjects(tempArray)
    }, [props?.service?.projectsReadyForService])

    useEffect(() => {
        if (selectedProject?.value) {
            props?.actions?.getProductsOfProject(selectedProject?.value)
        }
        if(selectedProject?.firstName){
            setFirstName(selectedProject?.firstName)
        }

        if(selectedProject?.lastName){
            setLastName(selectedProject?.lastName)
        }

        if(selectedProject?.address){
            setAddress(selectedProject?.address)
        }

        if(selectedProject?.city){
            setCity(selectedProject?.city)
        }

        if(selectedProject?.email){
            setEmail(selectedProject?.email)
        }

        if(selectedProject?.phoneNumber){
            setPhoneNumber(selectedProject?.phoneNumber)
        }

    }, [selectedProject])

    useEffect(() => {
        let service = props.service?.fetchedService
        console.log(service,'service')
        if(props?.notRegisteredClients){
            if (service) {
                console.log(service,'asd')
                setFirstName(service?.firstname)
                setLastName(service?.lastname)
                setAddress(service?.address)
                setCity(service?.city)
                setEmail(service?.email)
                setPhoneNumber(service?.phoneNumber)
                setWarning(service?.warning)
                setProblem(service?.problem)
                if(service?.start){
                    setStartTime(service?.start)
                }
                if(service?.end){
                    setEndTime(service?.end)
                }
                let tempArray = []
                service?.servicingFiles?.forEach((file) => {
                    if (file?.servicingFileWithUrl) {
                        tempArray.push({ id: file.id, fileUrl: file?.servicingFileWithUrl, name: file?.servicingFileName })
                    }
                })
                setAssets(tempArray)
                let tempArrayOfProducts = []
                service?.productsServicing?.forEach((product) => {
                    tempArrayOfProducts?.push({
                        value: product?.article?.id,
                        label: product?.article?.name
                    })
                })
                setSelectedProducts(tempArrayOfProducts)
                if(service?.date){
                    setServiceDate(service?.date)
                }
                if(service?.status?.value){
                    setServiceStatus(service?.status)
                }
            }
        }else{
            if (service) {
                setSelectedProject({
                    label: service?.project?.name,
                    value: service?.project?.id
                })
                setFirstName(service?.client?.firstName)
                setLastName(service?.client?.lastName)
                setAddress(service?.client?.address)
                setCity(service?.client?.city)
                setEmail(service?.client?.email)
                setPhoneNumber(service?.client?.phoneNumber)
                setWarning(service?.warning)
                setProblem(service?.problem)
                if(service?.start){
                    setStartTime(service?.start)
                }
                if(service?.end){
                    setEndTime(service?.end)
                }
                let tempArray = []
                service?.servicingFiles?.forEach((file) => {
                    if (file?.servicingFileWithUrl) {
                        tempArray.push({ id: file.id, fileUrl: file?.servicingFileWithUrl, name: file?.servicingFileName })
                    }
                })
                setAssets(tempArray)
                let tempAgreementFilesArray = []
                service?.agreementFiles?.forEach((file) => {
                    if(file?.signatureWithUrl){
                        tempAgreementFilesArray.push({id: file.id, fileUrl: file?.signatureWithUrl, name: file?.signature})
                    }else if(file?.finalAgreementWithUrl){
                        tempAgreementFilesArray.push({id: file.id, fileUrl: file?.finalAgreementWithUrl, name: file?.finalAgreementName})
                    }else if(file?.annexesWithUrl){
                        tempAgreementFilesArray.push({id: file.id, fileUrl: file?.annexesWithUrl, name: file?.annexes})
                    }
                })
                setAgreementAssets(tempAgreementFilesArray)
                let tempArrayOfProducts = []
                service?.productsServicing?.forEach((product) => {
                    tempArrayOfProducts?.push({
                        value: product?.productId,
                        label: product?.name
                    })
                })
                setSelectedProducts(tempArrayOfProducts)
                if(service?.date){
                    setServiceDate(service?.date)
                }
                if(service?.status?.value){
                    setServiceStatus(service?.status)
                }
            }
        }
    }, [props?.service?.fetchedService])

    useEffect(() => {
        if (serviceId) {
            props.serviceActions.getService(serviceId)
        } else {
            resetFields()
        }
    }, [serviceId])

    function resetFields() {
        setSelectedProject(null)
        setFirstName('')
        setLastName('')
        setPhoneNumber('')
        setEmail('')
        setProblem('')
        setAddress('')
        setCity('')
        setWarning('')
        setSelectedProducts([])
        setStartTime('')
        setEndTime('')
        setServiceDate('')
        setServiceStatus({})
        setAssets([])
        if(props?.notRegisteredClients){
            props.history.push('/dashboard/service/not-registered-clients')
        }else{
            props.history.push('/dashboard/service/create')
        }
    }

    function saveService() {
        let formData = new FormData()

        if(!props?.notRegisteredClients){
            if (selectedProject?.value) {
                formData.append('projectId', selectedProject?.value)
            } else {
                toast.error('Ju lutem zgjidhni projektin!')
                return
            }
        }
        if (problem) {
            formData.append('problem', problem)
        } else {
            toast.error('Komentoni ne problemin!')
            return
        }

        if (firstName) {
            formData.append('firstname', firstName);
        }
        if (lastName) {
            formData.append('lastname', lastName);
        }
        if (address) {
            formData.append('address', address);
        }
        if (city) {
            formData.append('city', city);
        }
        if (phoneNumber) {
            formData.append('phoneNumber', phoneNumber);
        }
        if (email) {
            formData.append('email', email);
        }


        if (warning) {
            formData.append('warning', warning);
        }
        if (serviceDate) {
            formData.append('date', moment(serviceDate)?.format('YYYY-MM-DD'))
        }
        if (startTime) {
            formData.append('start', startTime)
        }
        if (endTime) {
            formData.append('end', endTime)
        }
        if (serviceStatus?.value) {
            formData.append('servicingStatusId', serviceStatus?.value)
        }
        if(selectedProducts?.length !== 0){
            selectedProducts?.forEach((product) => {
                if(props?.notRegisteredClients){
                    formData.append("articleId[]", product?.value);
                }else{
                    formData.append("productId[]", product?.value);
                }
            })
        }
        if (addPhoto.length !== 0) {
            addPhoto?.forEach((file) => {
                formData.append("servicingFiles[]", file.file);
            })
        }

        if (serviceId) {
            props.serviceActions.editService(formData, serviceId).then(()=>{
                props?.history?.push('/dashboard/service')
            })
        } else {
            props.serviceActions.createService(formData).then(()=>{
                props?.history?.push('/dashboard/service')
            })
        }
    }

    const selectProjectForService = (projectForService) => {
        setSelectedProject(projectForService)
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Select',
            onClick: (event, rowData) => selectProjectForService(rowData)
        }
    ]

    function createServiceForm() {
        return (
            <div className={'w-100 mb-5 serviceContainer'}>
                <div className="d-flex mb-5">
                    <h2 className="main-title my-0">
                        <TranslatedText id="app.agreements.appendFinalAgreement"
                                        defaultMessage="Forma për paraqitje të rastit për servis"/>
                    </h2>
                    <Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm"
                                                                              defaultMessage="Pastro Formen"/></span>}>
                        <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}
                             alt="create client"/>
                    </Tooltip>
                </div>


                {/*<div className={'informationContainer'}>*/}
                {/*    {(!serviceId) && (!selectedProject?.value) && (!props?.notRegisteredClients) && <TableBbros*/}
                {/*        title={translatedText({*/}
                {/*            id: "table.warehouse",*/}
                {/*            defaultMessage: "Zgjedhni projektin per servisim"*/}
                {/*        })}*/}
                {/*        data={projects}*/}
                {/*        columns={SERVICE_PROJECTS}*/}
                {/*        options={OPTIONS}*/}
                {/*        actions={actions}*/}
                {/*        isLoading={props.loading}*/}
                {/*    />}*/}
                {/*</div>*/}
                {!props?.notRegisteredClients && <div>
                    <MikaSelect options={projects} placeholder={'Kerko projektin...'} value={selectedProject}
                                setEntireOptionFunction={setSelectedProject}/>
                </div>}

                <>
                    <div className={`d-flex ${isMobile ? 'flex-column' : ''}`}>
                        <div className={`${isMobile ? 'w-100' : 'w-50'} d-flex justify-content-start`}>
                            <div className={`${isMobile ? 'w-100' : 'w-95'}`}>
                                <div className={`${isMobile ? "" : "d-flex"} `}>
                                    <Input
                                        name="comments"
                                        label="label"
                                        id="comments"
                                        defaultValue=''
                                        inputWrapperClass={`${isMobile ? 'w-100' : 'w-50 pr-15'}  textarea-wrapper`}
                                        type="text"
                                        inputTextType={EInputTextType.SimpleInput}
                                        value={firstName || ''}
                                        disabled={!props?.notRegisteredClients}
                                        onChange={(name, value) => setFirstName(value)}
                                    >
                                        <p className={'m-0 font-weight-bold'}><TranslatedText
                                            id="app.agreements.appendFinalAgreement" defaultMessage="EMRI"/></p>
                                    </Input>
                                    <Input
                                        name="comments"
                                        label="label"
                                        id="comments"
                                        defaultValue=''
                                        inputWrapperClass={`${isMobile ? 'w-100' : 'w-50 pr-15'}  textarea-wrapper`}
                                        type="text"
                                        inputTextType={EInputTextType.SimpleInput}
                                        value={lastName || ''}
                                        disabled={!props?.notRegisteredClients}
                                        onChange={(name, value) => setLastName(value)}

                                    >
                                        <p className={'m-0 font-weight-bold'}><TranslatedText
                                            id="app.agreements.appendFinalAgreement" defaultMessage="MBIEMRI"/></p>
                                    </Input>
                                </div>
                                <div className={`${isMobile ? "" : "d-flex "}`}>
                                    <MikaSelect
                                        options={SERVICE_STATUSES}
                                        value={serviceStatus || ''}
                                        setEntireOptionFunction={(e)=>setServiceStatus(e)}
                                        placeholder={'Zgjedhni nje status per servisim'}
                                        selectWrapperClassName={`mt-2 ${isMobile ? "w-100" : "w-50 pr-15"} heightFitContent`}>
                                        <TranslatedText id="productCode" defaultMessage="Statusi i servisimit"/>
                                    </MikaSelect>
                                    <div className={`${isMobile ? "w-100" : "w-50"}`}>
                                        <Input
                                            name="serviceDate"
                                            label="label"
                                            id="serviceDate"
                                            value={serviceDate || ''}
                                            defaultValue=''
                                            type="date"
                                            onChange={(name, value) => setServiceDate(value)}
                                            inputTextType={EInputTextType.SimpleInput}
                                            inputWrapperClass={`simple-date-input w-100 mb-0 ${isMobile ? "" : "pr-15"} heightFitContent ${serviceDate ? " " : 'does-not-have-data'}`}>

                                            <TranslatedText id="table.serviceDate" defaultMessage="Data e servisimit"/>
                                        </Input>
                                    </div>
                                </div>
                                <div className={''}>
                                    <Select
                                        id={`projectMultiSelect`}
                                        name="projectMultiSelect"
                                        options={props?.notRegisteredClients ? props?.articles : projectProducts}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        className={'assigneeSelect pr-15'}
                                        classNamePrefix="react-select"
                                        value={selectedProducts || ''}
                                        onChange={(value) => setSelectedProducts(value)}
                                        placeholder={translatedText({
                                            id: "productOfProjectForService",
                                            defaultMessage: "Produktet e projektit per servisim"
                                        })}
                                    />
                                </div>
                                <Input
                                    name="comments"
                                    label="label"
                                    id="comments"
                                    defaultValue=''
                                    inputWrapperClass={"w-100 pr-15 textarea-wrapper"}
                                    type="textarea"
                                    inputTextType={EInputTextType.SimpleInput}
                                    value={problem || ''}
                                    onChange={(name, value) => setProblem(value)}
                                >
                                    <p className={'m-0 font-weight-bold'}><TranslatedText
                                        id="app.agreements.appendFinalAgreement" defaultMessage="PROBLEMI"/></p>
                                </Input>
                            </div>
                        </div>
                        <div className={`${isMobile ? 'w-100' : 'w-50'} d-flex justify-content-end`}>
                            <div className={`${isMobile ? 'w-100' : 'w-95'}`}>
                                <div className={` ${isMobile ? "" : "d-flex"}`}>
                                    <Input
                                        name="comments"
                                        label="label"
                                        id="comments"
                                        defaultValue=''
                                        inputWrapperClass={`${isMobile ? "" : "w-50 pr-15"} textarea-wrapper`}
                                        type="text"
                                        inputTextType={EInputTextType.SimpleInput}
                                        value={address || ''}
                                        disabled={!props?.notRegisteredClients}
                                        onChange={(name, value) => setAddress(value)}

                                    >
                                        <p className={'m-0 font-weight-bold'}><TranslatedText
                                            id="app.agreements.appendFinalAgreement" defaultMessage="ADRESSA"/></p>
                                    </Input>
                                    <Input
                                        name="comments"
                                        label="label"
                                        id="comments"
                                        defaultValue=''
                                        inputWrapperClass={`${isMobile ? "" : "w-50 pr-15"} textarea-wrapper`}
                                        type="text"
                                        inputTextType={EInputTextType.SimpleInput}
                                        value={city || ''}
                                        disabled={!props?.notRegisteredClients}
                                        onChange={(name, value) => setCity(value)}

                                    >
                                        <p className={'m-0 font-weight-bold'}><TranslatedText
                                            id="app.agreements.appendFinalAgreement" defaultMessage="QYTETI"/></p>
                                    </Input>
                                </div>
                                <div className={` ${isMobile ? "" : "d-flex"}`}>
                                    <Input
                                        name="comments"
                                        label="label"
                                        id="comments"
                                        defaultValue=''
                                        inputWrapperClass={`${isMobile ? "" : "w-50 pr-15"} textarea-wrapper`}
                                        type="text"
                                        inputTextType={EInputTextType.SimpleInput}
                                        value={phoneNumber || ''}
                                        disabled={!props?.notRegisteredClients}
                                        onChange={(name, value) => setPhoneNumber(value)}

                                    >
                                        <p className={'m-0 font-weight-bold'}><TranslatedText
                                            id="app.agreements.appendFinalAgreement" defaultMessage="NR. TELEFONIT"/>
                                        </p>
                                    </Input>
                                    <Input
                                        name="comments"
                                        label="label"
                                        id="comments"
                                        defaultValue=''
                                        inputWrapperClass={`${isMobile ? "" : "w-50 pr-15"} textarea-wrapper`}
                                        type="text"
                                        inputTextType={EInputTextType.SimpleInput}
                                        value={email || ''}
                                        disabled={!props?.notRegisteredClients}
                                        onChange={(name, value) => setEmail(value)}
                                    >
                                        <p className={'m-0 font-weight-bold'}><TranslatedText
                                            id="app.agreements.appendFinalAgreement" defaultMessage="EMAIL"/></p>
                                    </Input>
                                </div>
                                <div className={` ${isMobile ? "" : "w-100 d-flex"}`}>
                                    <div className={`${isMobile ? "" : "w-50 pr-12"} dateTimeContainer`}>
                                        <Input
                                            name="startTime"
                                            label="label"
                                            id="orderAcceptationDate"
                                            inputWrapperClass={"w-100"}
                                            type="text"
                                            inputTextType={EInputTextType.SimpleInput}
                                            value={startTime ? moment(startTime).format('DD-MM-YYYY HH:mm:ss') : ''}
                                            disabled

                                        >
                                            <p className={'m-0'}>Data e nisjes se servisit</p>
                                            <div className={'clearDate'}>
                                                <p className={'x-sign'}
                                                   onClick={() => setStartTime('')}>+</p>
                                            </div>
                                            {((!props.permissions.observer) || props.permissions.fullPrivileges) &&
                                            <Tooltip placement="right"
                                                     overlay={<span>Ruaj kohen</span>}>
                                                <img src={clock} alt=""
                                                     className="saveTimeLogoService"
                                                     onClick={() => setStartTime(moment().format("YYYY-MM-DD HH:mm:ss"))}/>
                                            </Tooltip>}
                                        </Input>
                                    </div>
                                    <div className={`${isMobile ? "" : "w-50 pr-12"} dateTimeContainer`}>
                                        <Input
                                            name="endTime"
                                            label="label"
                                            id="orderAcceptationDate"
                                            inputWrapperClass={"w-100"}
                                            type="text"
                                            inputTextType={EInputTextType.SimpleInput}
                                            value={endTime ? moment(endTime).format('DD-MM-YYYY HH:mm:ss') : ''}
                                            disabled

                                        >
                                            <p className={'m-0'}>Koha e perfundimit te servisit</p>
                                            <div className={'clearDate'}>
                                                <p className={'x-sign'}
                                                   onClick={() => setEndTime('')}>+</p>
                                            </div>
                                            {((!props.permissions.observer) || props.permissions.fullPrivileges) &&
                                            <Tooltip placement="right"
                                                     overlay={<span>Ruaj kohen</span>}>
                                                <img src={clock} alt=""
                                                     className="saveTimeLogoService"
                                                     onClick={() => setEndTime(moment().format("YYYY-MM-DD HH:mm:ss"))}/>
                                            </Tooltip>}
                                        </Input>
                                    </div>
                                </div>
                                <Input
                                    name="comments"
                                    label="label"
                                    id="comments"
                                    defaultValue=''
                                    inputWrapperClass={"w-100 textarea-wrapper pr-15"}
                                    type="textarea"
                                    inputTextType={EInputTextType.SimpleInput}
                                    value={warning || ''}
                                    onChange={(name, value) => setWarning(value)}
                                >
                                    <p className={'m-0 font-weight-bold'}><TranslatedText
                                        id="app.agreements.appendFinalAgreement" defaultMessage="VEREJTJA"/></p>
                                </Input>
                            </div>
                        </div>
                    </div>
                    <div className={'filesContainer'}>
                        <MikaFileInput labelKey={translatedText({
                            id: "offer.addPhoto",
                            defaultMessage: "Shto foto"
                        })} multiple setFiles={setAddPhoto} value={addPhoto} className={`${isMobile ? "w-100 mb-3" : "w-25"}`}/>
                        <div className={'assetContainer'}>
                            <div>
                                {assets?.length > 0 &&
                                <>
                                    {assets?.map((item, id) => {
                                        return (
                                            <div className="d-flex align-items-center mb-2 cursor-pointer position-relative"
                                                 key={id}>
                                                <img className="product-pic m-1" src={PICFile} alt="pic"/>
                                                <a target={'_blank'} href={item.fileUrl}
                                                   className="link-pics">{item.name}</a>
                                                {/*<div className="fileRemovalButton" onClick={() => removeFile(item?.id)}>*/}
                                                {/*    <img src={DifferentColoredPlus} alt="close" />*/}
                                                {/*</div>*/}
                                            </div>
                                        )
                                    })}
                                </>
                                }
                            </div>
                            {installationPhotos?.length !== 0 && <div className="measureImagesContainer">
                                <Tooltip
                                    placement="right"
                                    overlay={
                                        <span>
                                                            <TranslatedText id="app.clearForm"
                                                                            defaultMessage="Ruaj se pari oferten pastaj printo"/>
                                                        </span>
                                    }>
                                    <ReactToPrint
                                        trigger={() => <button
                                            // disabled={props.offers.length > 0}
                                            className="printButton d-flex align-items-center">
                                            <p className="m-0">
                                                <TranslatedText id="app.installationPhotos"
                                                                defaultMessage="Fotot e instalimit"/>
                                            </p>
                                            <img src={FileIcon} alt="" className="mx-2"/>
                                        </button>}
                                        content={() => componentRef.current}
                                    />
                                </Tooltip>
                                <MeasureImages images={installationPhotos} ref={componentRef}/>
                            </div>}
                        </div>
                    </div>
                    <div className={'w-100 d-flex justify-content-between'}>
                        <div>
                            {agreementAssets?.length > 0 &&
                            <>
                                {agreementAssets?.map((item, id) => {
                                    return (
                                        <div className="d-flex align-items-center mb-2 cursor-pointer position-relative"
                                             key={id}>
                                            <img className="product-pic m-1" src={PICFile} alt="pic"/>
                                            <a target={'_blank'} href={item.fileUrl}
                                               className="link-pics">{item.name}</a>
                                            {/*<div className="fileRemovalButton" onClick={() => removeFile(item?.id)}>*/}
                                            {/*    <img src={DifferentColoredPlus} alt="close" />*/}
                                            {/*</div>*/}
                                        </div>
                                    )
                                })}
                            </>
                            }
                        </div>
                        {((!props.permissions.observer) || props.permissions.fullPrivileges) && <Button
                            isLoading={props?.buttonLoader}
                            isDisabled={props?.buttonLoader}
                            onClick={saveService}
                            className="my-5 w-25"
                            buttonType={EButtonType.PurpleButton}>
                            <TranslatedText id="client.editContact"
                                            defaultMessage="Ruaj"/>
                        </Button>}
                    </div>
                </>
            </div>
        )
    }

    return (
        <div className="wrapper">
            {props.loading ? <Spinner/> : createServiceForm()}
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        service: state.service,
        installation: state.installation,
        transport: state.transport
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        serviceActions: bindActionCreators(serviceActions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch),
        installationActions: bindActionCreators(installationActions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateServiceForm as any)))