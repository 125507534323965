import axios from "axios";

export const url = 'https://shehuback.mikasoft.tech/'

const apiUrl = 'https://shehuback.mikasoft.tech/api/v1/';

export function apiCaller<T>(method: string, url: any, data?: any, givenHeaders?: HeadersInit, isStringify?: boolean):Promise<T[] | null> {
    const token = localStorage.getItem('mikaBearerToken')
    return fetch(apiUrl + url, {
        method,
        headers: givenHeaders !== null ?
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        } : givenHeaders ,
        body: data ? (isStringify !== true ? JSON.stringify(data):data ) : null
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        return res.json().then((responseJson) => {
            throw (JSON.stringify(
                {
                    details: responseJson.errors,
                    status: res.status
                }
            ));
        })
    })
}

export function axiosCaller<T>(method: string, url: any, data?: any, givenHeaders?: object, isStringify?: boolean) {
    const token = localStorage.getItem('mikaBearerToken')
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
    }
    if(method === "POST"){
        return axios.post(apiUrl + url, data,{headers}).then(res => {
            return res
        })
    }else if(method === "GET"){
        return axios.get(apiUrl + url,{headers}).then(res => {
            return res
        })
    }else if(method === "DELETE"){
        return axios.delete(apiUrl + url,{headers}).then(res => {
            return res
        })
    }else if(method === "PATCH"){
        return axios.patch(apiUrl + url, data,{headers}).then(res => {
            return res
        })
    }
}
