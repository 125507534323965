import * as React from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from 'react-redux'
import {RootState} from '../../reducers'
import './Navbar.scss'
import {bindActionCreators, Dispatch} from 'redux'
import * as actions from '../../actions/generalActions'
import {withRouter} from 'react-router'
import {Link, Route} from 'react-router-dom';
import AdminNav from '../../containers/Admin/AdminNav';
import classNames from 'classnames';
import measureSvg from '../../assets/icons/measure.svg'
import productionSvg from '../../assets/icons/production.svg'
import contactSvg from '../../assets/icons/contact.svg'
import offerSvg from '../../assets/icons/offer.svg'
import warehouseSvg from '../../assets/icons/warehouse.svg'
import agreementSvg from '../../assets/icons/agreement.svg'
import transportSvg from '../../assets/icons/transport.svg'
import installationSvg from '../../assets/icons/installation.svg'
import serviceSvg from '../../assets/icons/service.svg'
import impressionSvg from '../../assets/icons/impression.svg'
import TranslatedText, {translatedText} from "../TranslatedText";
import {isMobile} from "../../constants/constants";

interface Props {
    step: number;
    actions: any;
    history: any;
    intl: any;
    isNavOpen: boolean;
    isAdminPanelOpen: boolean;
    permissions: any
}

interface State {
    navbar: string[],
}

class Navbar extends React.Component<Props, State> {

    constructor(props: any) {
        super(props)
        this.state = {
            navbar: [
                translatedText({id: "contact", defaultMessage: "Kontakti"}),
                translatedText({id: "measure", defaultMessage: "Matja"}),
                // translatedText({id:"projectManagement", defaultMessage: "Menaxhimi i projektit"}),
                translatedText({id: "offer", defaultMessage: "Oferta"}),
                translatedText({id: "agreement", defaultMessage: "Marreveshja"}),
                translatedText({id: "production", defaultMessage: "Prodhimi"}),
                translatedText({id: "warehouse", defaultMessage: "Depo"}),
                translatedText({id: "transport", defaultMessage: "Transporti"}),
                translatedText({id: "installation", defaultMessage: "Instalimi"}),
                translatedText({id: "service", defaultMessage: "Servisimi"}),
                translatedText({id: "impression", defaultMessage: "Pershtypja"}),
            ]
        }
    }



    render() {

        const icons = {
            contact: contactSvg,
            measures: measureSvg,
            // projectManagement: measureSvg,
            offers: offerSvg,
            agreements: agreementSvg,
            production: productionSvg,
            warehouse: warehouseSvg,
            transport: transportSvg,
            installation: installationSvg,
            service: serviceSvg,
            impression: impressionSvg
        }


        const steps = {
            0: "contact",
            1: "measures",
            // 2: "projectManagement",
            2: "offers",
            3: "agreements",
            4: "production",
            5: "warehouse",
            6: "transport",
            7: "installation",
            8: "service",
            9: "impression"
        }

        function toggleNavBar(enter, navOpened, setNavOpened) {
            if(!isMobile){
                if (localStorage.getItem("mikaNavbarToggled") !== 'true') {
                    if (enter) {
                        setNavOpened(false)
                    } else {
                        setNavOpened(true)
                    }
                }
            }

        }


        let thisProps = this.props
        function onClickCloseNavbarForMobile(){
            if(isMobile){
                console.log(thisProps,"thisProps")
                thisProps?.actions?.setNavBarOpen(true)
            }
        }

        let pathName = this.props.history.location.pathname.split("/")
        let activeModule = pathName[2]
        return (
            <div className={classNames("left-navbar", {
                'collapsed': this.props.isNavOpen
            })} onMouseEnter={() => toggleNavBar(true, this.props.isNavOpen, this.props?.actions.setNavBarOpen)}
                 onMouseLeave={() => toggleNavBar(false, this.props.isNavOpen, this.props?.actions.setNavBarOpen)}>
                <Route path="/dashboard" render={() =>
                    <ul>
                        {this.state.navbar.map((name: string, index: number) => {
                                return (
                                    <li className={`row-li ${!this.props.isNavOpen && activeModule === steps[index] ? "active" : ""}`}
                                        key={index}>
                                        {/*key={index} onClick={onClickCloseNavbarForMobile}>*/}
                                        {(this.props?.permissions?.fullPrivileges || this.props?.permissions[steps[index]]) &&
                                        <Link to={`/dashboard/${steps[index]}${steps[index] === 'installation' ? '/records' : ''}`}>
                                            <div className="d-flex align-items-center">
                                                <div className="circle">
                                                    <img src={icons[steps[index]]} alt=""/>
                                                </div>
                                                <span className="border-circle"></span>
                                                <span className="title">{name}</span>
                                            </div>
                                        </Link>}
                                        {activeModule === steps[index] && Object.keys(this.props?.permissions).length !== 0 &&
                                        <div
                                            className={`d-flex sub-menu-container ${this?.props?.isNavOpen ? 'd-none   ' : ''}`}>
                                            {steps[index] &&
                                            <ul className="sub-menu">
                                                {this.props?.permissions?.fullPrivileges &&
                                                <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}${steps[index] === 'installation' ? '/records' : ''}`}>
                                                    <li>
                                                        <TranslatedText id="all"
                                                                        defaultMessage="Shiko te gjitha"/>
                                                    </li>
                                                </Link>}
                                                {steps[index] === 'contact' && <>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/contact/contactsForMeasure`}>
                                                        <li>
                                                            <TranslatedText id="clientsForMeasure"
                                                                            defaultMessage="Klientat per matje"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/contact/create`}>
                                                        <li>
                                                            <TranslatedText id="createNew"
                                                                            defaultMessage="Krijo te re"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/archive/${steps[index]}`}>
                                                        <li>
                                                            <TranslatedText id="archive"
                                                                            defaultMessage="Arkiva"/>
                                                        </li>
                                                    </Link>
                                                    {/*<Link onClick= to={`/dashboard/${steps[index]}/2`}>*/}
                                                    {/*    <li>*/}
                                                    {/*        <TranslatedText id="archive"*/}
                                                    {/*                        defaultMessage="Arkiva"/>*/}
                                                    {/*    </li>*/}
                                                    {/*</Link>*/}
                                                </>}
                                                {steps[index] === 'measures' && <>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/3`}>
                                                        <li>
                                                            <TranslatedText id="onHold"
                                                                            defaultMessage="Ne pritje"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/1`}>
                                                        <li>
                                                            <TranslatedText id="initial"
                                                                            defaultMessage="Fillestare"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/2`}>
                                                        <li>
                                                            <TranslatedText id="archive"
                                                                            defaultMessage="Finale"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/passive`}>
                                                        <li>
                                                            <TranslatedText id="passive"
                                                                            defaultMessage="Pasive"/>
                                                        </li>
                                                    </Link>
                                                </>}
                                                {steps[index] === 'offers' && <>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/5`}>
                                                        <li>
                                                            <TranslatedText id="waiting"
                                                                            defaultMessage="Ne pritje"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/3`}>
                                                        <li>
                                                            <TranslatedText id="sentForConfirmation"
                                                                            defaultMessage="E derguar per konfirmim"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/1`}>
                                                        <li>
                                                            <TranslatedText id="rejected"
                                                                            defaultMessage="Te refuzuara"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/4`}>
                                                        <li>
                                                            <TranslatedText id="undefined"
                                                                            defaultMessage="Te padefinuara"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/canceled/products`}>
                                                        <li>
                                                            <TranslatedText id="canceledProducts"
                                                                            defaultMessage="Produktet e anuluara"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/2`}>
                                                        <li>
                                                            <TranslatedText id="archive"
                                                                            defaultMessage="Arkiva"/>
                                                        </li>
                                                    </Link>
                                                </>}
                                                {steps[index] === 'agreements' && <>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/1`}>
                                                        <li>
                                                            <TranslatedText id="onProductionHold"
                                                                            defaultMessage="Ne pritje per prodhim"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/2`}>
                                                        <li>
                                                            <TranslatedText id="onProductionProcess"
                                                                            defaultMessage="Ne process te prodhimit"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/3`}>
                                                        <li>
                                                            <TranslatedText id="inWarehouse"
                                                                            defaultMessage="Ne depo"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/5`}>
                                                        <li>
                                                            <TranslatedText id="cancelled"
                                                                            defaultMessage="E anuluar"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/4`}>
                                                        <li>
                                                            <TranslatedText id="archive"
                                                                            defaultMessage="Arkiva"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/project/calendar`}>
                                                        <li>
                                                            <TranslatedText id="archive"
                                                                            defaultMessage="Kalendari i marrveshjeve"/>
                                                        </li>
                                                    </Link>
                                                </>}
                                                {(this.props?.permissions?.fullPrivileges || this.props?.permissions[steps[index]]) &&
                                                <>
                                                    <Link onClick={onClickCloseNavbarForMobile}
                                                        to={`/dashboard/${steps[index]}/${steps[index] === 'warehouse' ? 'warehouseProducts' : 'create'}`}>
                                                        {steps[index] === 'production' ?
                                                            <li>
                                                                <TranslatedText id="productionInformation"
                                                                                defaultMessage="Info per prodhimtari"/>
                                                            </li>
                                                            :
                                                            <>
                                                                <>
                                                                    {/*{steps[index] !== 'offers' && steps[index] !== 'agreements' && steps[index] !== 'measures' &&*/}
                                                                    {/*<li >*/}
                                                                    {/*    <TranslatedText id="app.navigation.createNew"*/}
                                                                    {/*                      defaultMessage="Krijo te re"/>*/}
                                                                    {/*</li>}*/}
                                                                </>
                                                            </>
                                                        }
                                                    </Link>

                                                </>}
                                                {steps[index] === 'warehouse' &&
                                                <>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/warehouseState`}>
                                                        <li>
                                                            <TranslatedText id="warehouseState"
                                                                            defaultMessage="Gjendja e depos"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/warehouseProducts`}>
                                                        <li>
                                                            <TranslatedText id="app.navigation.createNew"
                                                                            defaultMessage="Produktet e blera"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/inner-stock`}>
                                                        <li>
                                                            <TranslatedText id="innerStock"
                                                                            defaultMessage="Stoku i brendshem"/>
                                                        </li>
                                                    </Link>
                                                    <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/clients`}>
                                                        <li>
                                                            <TranslatedText id="clientsOnWarehouse"
                                                                            defaultMessage="Klientat ne depo"/>
                                                        </li>
                                                    </Link>
                                                </>
                                                }
                                                {steps[index] === 'production' ? <>
                                                        {this.props?.permissions?.fullPrivileges && <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/products`}>
                                                            <li>
                                                                <TranslatedText id="productionProducts"
                                                                                defaultMessage="Produktet ne prodhim"/>
                                                            </li>
                                                        </Link>}
                                                        {this.props?.permissions?.fullPrivileges &&
                                                        <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/production/calendar`}>
                                                            <li>
                                                                <TranslatedText id="calendar"
                                                                                defaultMessage="Kalendari"/>
                                                            </li>
                                                        </Link>}
                                                        {this.props?.permissions?.fullPrivileges && <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/production/archived-responsibilities`}>
                                                            <li>
                                                                <TranslatedText id="archivedProcesses"
                                                                                defaultMessage="Arkiva e proceseve"/>
                                                            </li>
                                                        </Link>}
                                                        <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/production/production-archive`}>
                                                            <li>
                                                                <TranslatedText id="archivedProducts"
                                                                                defaultMessage="Arkiva e prodhimit"/>
                                                            </li>
                                                        </Link>
                                                        {/*<Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/production/production-rapport`}>*/}
                                                        {/*    <li>*/}
                                                        {/*        <TranslatedText id="archivedProducts"*/}
                                                        {/*                        defaultMessage="Raporti i prodhimit"/>*/}
                                                        {/*    </li>*/}
                                                        {/*</Link>*/}
                                                        <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/production/archived-products`}>
                                                            <li>
                                                                <TranslatedText id="archivedProducts"
                                                                                defaultMessage="Arkiva e produkteve"/>
                                                            </li>
                                                        </Link>
                                                        <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/production/returned-processes`}>
                                                            <li>
                                                                <TranslatedText id="returnedProcesses"
                                                                                defaultMessage="Proceset e kthyera"/>
                                                            </li>
                                                        </Link>
                                                        {this?.props?.permissions?.fullPrivileges &&
                                                        <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/production/passive-products`}>
                                                            <li>
                                                                <TranslatedText id="passiveProducts"
                                                                                defaultMessage="Produktet pasive"/>
                                                            </li>
                                                        </Link>}
                                                    </> :
                                                    <>
                                                        {(steps[index] !== 'transport' && steps[index] !== 'installation' && steps[index] !== 'service' && steps[index] !== 'impression' && steps[index] !== 'contact' && steps[index] !== 'measures' && steps[index] !== 'agreements' && steps[index] !== 'offers') &&
                                                        <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/archive/${steps[index]}`}>
                                                            <li>
                                                                <TranslatedText id="archive"
                                                                                defaultMessage="Arkiva"/>
                                                            </li>
                                                        </Link>}
                                                        {(steps[index] === 'transport') &&
                                                        <>
                                                            <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/status=1&withoutTransport=0`}>
                                                                <li>
                                                                    <TranslatedText id="onHold"
                                                                                    defaultMessage="Ne pritje"/>
                                                                </li>
                                                            </Link>
                                                            <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/status=2&withoutTransport=0`}>
                                                                <li>
                                                                    <TranslatedText id="ongoing"
                                                                                    defaultMessage="Te nisura"/>
                                                                </li>
                                                            </Link>
                                                            <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/status=3&withoutTransport=0`}>
                                                                <li>
                                                                    <TranslatedText id="complete"
                                                                                    defaultMessage="Te perfunduara"/>
                                                                </li>
                                                            </Link>
                                                            <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/withoutTransport=1`}>
                                                                <li>
                                                                    <TranslatedText id="complete"
                                                                                    defaultMessage="Produktet pa transport"/>
                                                                </li>
                                                            </Link>
                                                            <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/records/map`}>
                                                                <li>
                                                                    <TranslatedText id="app.navigation.createNew"
                                                                                    defaultMessage="Harta"/>
                                                                </li>
                                                            </Link>
                                                        </>
                                                        }
                                                    </>}
                                                <>
                                                    { steps[index] === 'installation' &&
                                                        <>
                                                            <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/records/status=1`}>
                                                                <li>
                                                                    <TranslatedText id="onHold"
                                                                                    defaultMessage="Ne pritje"/>
                                                                </li>
                                                            </Link>
                                                            <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/records/status=2`}>
                                                                <li>
                                                                    <TranslatedText id="ongoing"
                                                                                    defaultMessage="Te nisura"/>
                                                                </li>
                                                            </Link>
                                                            <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/records/status=3`}>
                                                                <li>
                                                                    <TranslatedText id="complete"
                                                                                    defaultMessage="Te perfunduara"/>
                                                                </li>
                                                            </Link>
                                                            <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/records/withoutInstallation=1`}>
                                                                <li>
                                                                    <TranslatedText id="complete"
                                                                                    defaultMessage="Produktet pa instalim"/>
                                                                </li>
                                                            </Link>
                                                            <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/map/records`}>
                                                                <li>
                                                                    <TranslatedText id="app.navigation.createNew"
                                                                                    defaultMessage="Harta"/>
                                                                </li>
                                                            </Link>
                                                        </>
                                                    }
                                                </>
                                                <>
                                                    { steps[index] === 'service'&&
                                                    <>

                                                        <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/records/1`}>
                                                            <li>
                                                                <TranslatedText id="createNew"
                                                                                defaultMessage="Serviset ne pritje"/>
                                                            </li>
                                                        </Link>
                                                        <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/records/2`}>
                                                            <li>
                                                                <TranslatedText id="createNew"
                                                                                defaultMessage="Serviset ne proces"/>
                                                            </li>
                                                        </Link>
                                                        <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/records/3`}>
                                                            <li>
                                                                <TranslatedText id="createNew"
                                                                                defaultMessage="Serviset e perfunduara"/>
                                                            </li>
                                                        </Link>
                                                        <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/records-not-registered-clients`}>
                                                            <li>
                                                                <TranslatedText id="notRegisteredClients"
                                                                                defaultMessage="Klientat e meparshem"/>
                                                            </li>
                                                        </Link>
                                                        <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/create`}>
                                                            <li>
                                                                <TranslatedText id="createNew"
                                                                                defaultMessage="Krijo tiketen"/>
                                                            </li>
                                                        </Link>
                                                        <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/map`}>
                                                            <li>
                                                                <TranslatedText id="map"
                                                                                defaultMessage="Harta"/>
                                                            </li>
                                                        </Link>
                                                    </>
                                                    }
                                                </>
                                                <>
                                                    { steps[index] === 'impression'&&
                                                    <>
                                                        <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/1`}>
                                                            <li>
                                                                <TranslatedText id="createNew"
                                                                                defaultMessage="Projektet ne pritje"/>
                                                            </li>
                                                        </Link>
                                                        <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/2`}>
                                                            <li>
                                                                <TranslatedText id="createNew"
                                                                                defaultMessage="Te gjitha pershtypjet"/>
                                                            </li>
                                                        </Link>
                                                        <Link onClick={onClickCloseNavbarForMobile} to={`/dashboard/${steps[index]}/create`}>
                                                            <li>
                                                                <TranslatedText id="createNew"
                                                                                defaultMessage="Krijo te re"/>
                                                            </li>
                                                        </Link>
                                                    </>
                                                    }
                                                </>
                                            </ul>
                                            }
                                        </div>
                                        }
                                    </li>
                                )
                            }
                        )}
                    </ul>
                }/>
                <Route path="/admin" render={() =>
                    <div className="admin-panel">
                        {/*<AdminNav/>*/}
                        <div className={'admin-nav'}>
                            <Link onClick={onClickCloseNavbarForMobile} to="/admin">
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>

                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="usersDashboard"
                                                                                                  defaultMessage="Ballina e perdoruesve"/></span>}
                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to="/admin/all-users">
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>

                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.allUsers"
                                                                                                  defaultMessage="Te gjithe perdoruesit"/></span>}
                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to="/admin/create-user">
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>

                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.createUser"
                                                                                                  defaultMessage="Krijo perdorues"/></span>}
                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to='/admin/departments'>
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>

                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.productionProcesses"
                                                                                                  defaultMessage="Repartet e prodhimit"/></span>}
                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to='/admin/responsibilities'>
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>

                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.productionProcesses"
                                                                                                  defaultMessage="Proceset e prodhimit"/></span>}
                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to='/admin/equipments'>
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>

                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.equipments" defaultMessage="Pajisjet"/></span>}
                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to='/admin/units'>
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>

                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.units" defaultMessage="Njesit"/></span>}
                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to='/admin/materials'>
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>

                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.materials" defaultMessage="Materialet"/></span>}

                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to='/admin/articles'>
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>

                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.articles" defaultMessage="Artikujt"/></span>}
                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to='/admin/suppliers'>
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>

                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.articles" defaultMessage="Furnitoret"/></span>}
                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to='/admin/measurement-sketch-templates'>
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>

                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.measurementSketchTemplates"
                                                                                                  defaultMessage="Template per matje"/></span>}
                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to='/admin/color-palettes'>
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>

                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.colorTypes"
                                                                                                  defaultMessage="Llojet e ngjyrave"/></span>}
                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to='/admin/offer-fields'>
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>

                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.offerFields"
                                                                                                  defaultMessage="Fushat e ofertes"/></span>}
                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to='/admin/offer-conditions'>
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>
                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.offerConditions"
                                                                                                  defaultMessage="Kushtet e ofertes"/></span>}
                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to='/admin/production-settings'>
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>
                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.projectSettings"
                                                                                                  defaultMessage="Preferencat e prodhimit"/></span>}
                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to='/admin/impression-questions'>
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>
                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.offerFields"
                                                                                                  defaultMessage="Pyetjet e pershtypjes"/></span>}
                                </div>
                            </Link>
                            <Link onClick={onClickCloseNavbarForMobile} to='/admin/audit-log'>
                                <div className="d-flex position-relative align-items-center">
                                    <div className={`circle ${this.props?.isNavOpen ? 'closed' : ''}`}></div>
                                    {!this.props?.isNavOpen && <span className="title"><TranslatedText id="app.header.auditLog"
                                                                                                  defaultMessage="Audit Log"/></span>}
                                </div>
                            </Link>
                        </div>
                        <div className="go-to-system">
                            <ul>
                                <li className="row-li">
                                    <Link to='/dashboard/contact'>
                                        <div
                                            onClick={() => this.props.actions.openAdminPanel(false)}
                                            className="d-flex">
                                            <div className="circle"></div>
                                            <span className="border-circle"></span>
                                            {!this.props?.isNavOpen && <span className="title">
                                                <FormattedMessage id="app.goToSystem"
                                                                  defaultMessage="Kthehu tek sistemi"/>
                                            </span>}
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                }/>
            </div>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        step: state.app.step
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Navbar)))