import React, { useEffect, useState } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import "./Contact.scss";
import { withRouter } from 'react-router';
import TableBbros from '../../components/Table/TableBbros';
import { ClientObject } from '../../types/client.type';
import {COLUMNS, COLUMNS_MOBILE, OPTIONS} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { Input } from '../../components/Input/Input';
import { Button, EButtonType } from '../../components/Button';
import { ReactComponent as ImportSvg } from '../../assets/icons/import.svg';
import ModalComponent from '../../components/Modal/ModalComponent';
import DeleteModalComponent from '../../components/Modal/DeleteModal';
import {translatedText} from "../../components/TranslatedText";
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";
import {MEASURE_STATUSES} from "../../constants/constants";
import moment from "moment";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import FontAwesome from 'react-fontawesome';
import {SelectOption} from "../../types/selectOption.type";
import {toast} from "react-toastify";


interface Props {
    intl: any;
    actions: any;
    clientActions: any;
    client: any;
    letter: string;
    history: any;
    loading: boolean;
    buttonLoader: boolean
}

const ShowAllClients = (props: Props) => {
    const [clientId, setClientId] = useState<number>(null);
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);
    const [contactImportFile, setContactImportFile] = useState({
        employerId: "",
        fileName: "",
        file: null
    })

    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [filtersOpened, setFiltersOpened] = useState<boolean>(false)

    useEffect(() => {
        if(props?.history?.location?.pathname === '/dashboard/contact'){
            props.clientActions.getAllClients()
        }else{
            props.clientActions.fetchClients()
        }
    }, [props?.history?.location?.pathname]);

    const editClient = (client: ClientObject) => {
        props.history.push({ pathname: `/dashboard/contact/create/${client?.id}` })
    }

    const handleRemove = (client: ClientObject) => {
        setDeleteModal(true)
        setClientId(client?.id)
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData: ClientObject) => editClient(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData: ClientObject) => handleRemove(rowData)
        }
    ]

    function filterData(){
        if(startDate && endDate){
            props.clientActions.fetchClients(startDate, endDate)
            setFiltersOpened(false)
        }else{
            props.clientActions.fetchClients()
        }
    }

    const submitImport = () => {
        let data = new FormData();
        data.append("clients", contactImportFile.file)
        props.clientActions.importClients(data);
    }

    const deleteUser = () => {
        props.clientActions.deleteClient(clientId).then(()=>{
            if(props?.history?.location?.pathname === '/dashboard/contact'){
                props.clientActions.getAllClients()
            }else{
                props.clientActions.fetchClients()
            }
            setDeleteModal(false)
        })
    }

    return (
        <div>
            <div className="wrapper position-relative">
                {/*<form className="import-section mobileDisplay" onSubmit={e => e.preventDefault()}>*/}
                {/*    <div className="col-12 d-flex justify-content-end upload-client-wrapper">*/}
                {/*        <Input*/}
                {/*            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"*/}
                {/*            isContact*/}
                {/*            isFocused*/}
                {/*            type="file"*/}
                {/*            maxLength={10}*/}
                {/*            inputWrapperClass="file-input upload-client"*/}
                {/*            id="contactImportFile"*/}
                {/*            name="clients"*/}
                {/*            placeholder=""*/}
                {/*            value={contactImportFile.fileName || ''}*/}
                {/*            onChange={(name, value, file) => {*/}
                {/*                setContactImportFile({*/}
                {/*                    ...contactImportFile,*/}
                {/*                    fileName: value,*/}
                {/*                    file: file,*/}
                {/*                    // [formConfig.upload.file.name]: file*/}
                {/*                })*/}
                {/*            }}>*/}
                {/*            <p></p>*/}
                {/*        </Input>*/}
                {/*        <Button*/}
                {/*            isLoading={props?.buttonLoader}*/}
                {/*        isDisabled={props?.buttonLoader}*/}
                {/*            onClick={submitImport}*/}
                {/*            buttonType={EButtonType.BasicLink}>*/}
                {/*            <ImportSvg className="import-icon" />*/}
                {/*        </Button>*/}
                {/*    </div>*/}
                {/*</form>*/}
                <div className={`productionFilterButton`}>
                    <div className={'w-100 h-100 position-relative'}>
                        <div className={'filterIconContainer'} onClick={() => setFiltersOpened(!filtersOpened)}>
                            <FontAwesome name={'filter'}/>
                        </div>
                        <div className={`filterDropdownContainer ${filtersOpened ? 'show' : 'hide'}`}>
                            <div className={'filterWrapper'}>
                                <div className={'closeFiltersButton'} onClick={() => setFiltersOpened(!filtersOpened)}>
                                    <FontAwesome name={'close'}/>
                                </div>
                                <div
                                    className={`dateContainer`}>
                                    <p className={'label'}>Data e fillimit</p>
                                    <p className={'dateValue'}>{startDate ? moment(startDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                    {startDate && <img src={DifferentColoredPlus} onClick={() => setStartDate('')}
                                                       className="x-signDate"/>}
                                    <div className={'calendar'}>
                                        <input type="date" value={startDate}
                                               onChange={(e) => setStartDate(e?.target?.value)}/>
                                        <div className={'calendarIcon'}>
                                            <FontAwesome
                                                name="calendar"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`dateContainer`}>
                                    <p className={'label'}>Data e mbarimit</p>
                                    <p className={'dateValue'}>{endDate ? moment(endDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                    {endDate &&
                                    <img src={DifferentColoredPlus} onClick={() => setEndDate('')} className="x-signDate"/>}
                                    <div className={'calendar'}>
                                        <input type="date" value={endDate} onChange={(e) => setEndDate(e?.target?.value)}/>
                                        <div className={'calendarIcon'}>
                                            <FontAwesome
                                                name="calendar"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    isLoading={props?.buttonLoader}
                                    isDisabled={props?.buttonLoader}
                                    className="m-auto w-100"
                                    onClick={filterData}
                                    buttonType={EButtonType.GreyButton}>
                                    Filtro
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <TableBbros
                    className={`${isDeleteModalOpen ? 'pointerEventsNone' : ''} firstColumnThinner`}
                    title={translatedText({ id: "contact", defaultMessage: "Kontakti" })}
                    data={props.client.clients}
                    columns={window.innerWidth > 500 ? COLUMNS : COLUMNS_MOBILE}
                    options={OPTIONS}
                    actions={actions}
                    isLoading={props.loading}
                />
                <ModalComponent
                    isModalHeader
                    isOpen={isDeleteModalOpen}
                    close={setDeleteModal}
                >
                    <DeleteModalComponent
                        history={props.history}
                        close={() => setDeleteModal(false)}
                        deleteFunction={() => deleteUser()} />
                </ModalComponent>
            </div>
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        user: state.user,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShowAllClients as any)))