import {bindActionCreators, Dispatch} from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import { withRouter } from 'react-router';
import './Production.scss'
import { Route, Switch } from 'react-router-dom';
import ShowAllProductions from './ShowAllProductions';
import CreateProductionsForm from './CreateProductionsFrom';
import ResponsibilitySlider from './ResponsibilitySlider';
import CalendarComponent from '../../components/Calendar/CalendarComponent';
import React, {useEffect} from "react";
import Archive from "../Archive/Archive";
import ArchivedProducts from "../Archive/ArchivedProducts";
import ArchivedResponsibilities from "../Archive/ArchivedResponsibilities";
import Page404 from "../Page404";
import PassiveProducts from "./PassiveProducts";
import ReturnedProcesses from "../Archive/ReturnedProcesses";
import ProductionProducts from "./ProductionProducts";
import CreateProductionsFrom from "./CreateProductionsFrom";
import ProductionRapport from "./ProductionRapport";

interface Props {
    actions: any
}

const Production = (props: Props) => {

    useEffect(()=>{
        props.actions.getUserPermissionsAndResponsibilities()
    }, [])

    return(
        <div className="h-100">
            <Switch>
                <Route exact path="/dashboard/production" component={ShowAllProductions} />
                <Route exact path="/dashboard/production/create/:clientId?" render={()=> <CreateProductionsFrom doneStatus={0}/> }/>
                <Route exact path="/dashboard/production/production-archive/:clientId?" render={()=> <CreateProductionsFrom doneStatus={1}/> }/>
                <Route exact path="/dashboard/production/production-rapport" component={ProductionRapport}/>
                <Route exact path="/dashboard/production/products" component={ProductionProducts}/>
                <Route exact path="/dashboard/production/userAssignment" component={ResponsibilitySlider}/>
                <Route exact path="/dashboard/production/calendar" render={()=> <CalendarComponent/> }/>
                <Route path="/dashboard/production/archive/:moduleName?" component={Archive} />
                <Route path="/dashboard/production/archived-products" component={ArchivedProducts} />
                <Route path="/dashboard/production/archived-responsibilities" component={ArchivedResponsibilities} />
                <Route path="/dashboard/production/returned-processes" component={ReturnedProcesses} />
                <Route path="/dashboard/production/passive-products" component={PassiveProducts} />
                <Route render={()=><Page404/>}/>
            </Switch>
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        agreements: state.agreements
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Production as any)));