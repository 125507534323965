import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as transportActions from '../../actions/transport';
import "./Transport.scss";
import {withRouter} from 'react-router';
import {User} from '../../types/user.type';
import Spinner from '../../components/Spinner';
import {ArticleInfomation} from '../../types/ArticleInformation.type';
import {SelectOption} from '../../types/selectOption.type';
import {EInputTextType, Input} from "../../components/Input/Input";
import Select from "react-select";
import {Button, EButtonType} from "../../components/Button";
import {toast} from "react-toastify";
import Tooltip from "rc-tooltip";
import clock from "../../assets/icons/clock.svg";
import moment from "moment";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import L, {LatLngExpression} from "leaflet";
import FontAwesome from 'react-fontawesome';
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import {useParams} from "react-router-dom";
import TimeTracker from "../../components/TimeTracker/TimeTracker";
import {isMobile, leafletMapIconBase64} from "../../constants/constants";

interface Props {
    intl: any;
    setLoading: boolean;
    actions: any;
    clientActions: any;
    client: any;
    loading: boolean;
    articles: ArticleInfomation[];
    validationMessageConfig: any;
    letter: string;
    app: any;
    user: any;
    measureUsers: User[];
    generalActions: any;
    allClients: SelectOption[];
    countries: any[];
    citiesFields: any[];
    transport: any;
    transportActions: any;
    archiveRecords: any;
    transportCode: string
    history: any
    buttonLoader: boolean
    permissions: any
}

const CreateContactForm = (props: Props) => {

    const [position, setPosition] = useState<LatLngExpression>()
    const [projects, setProjects] = useState<any>()
    const [neededEquipments, setNeededEquipments] = useState<any>()
    const [selectedProjects, setSelectedProjects] = useState<any>([])
    const [checkedEquipments, setCheckedEquipments] = useState<any>()
    const [allEquipmentsTaken, setAllEquipmentsTaken] = useState<any>()
    const [transportRecords, setTransportRecords] = useState<any[]>()
    const [showMap, setShowMap] = useState(false)
    const [withoutTransport, setWithoutTransport] = useState(false)

    useEffect(() => {
        if (props.transportCode) {
            props?.transportActions?.getTransportRecordsByCode(props.transportCode)
        } else {
            resetFields()
        }
        getCurrentPosition()
        props?.transportActions?.getProjectsForTransport(null, null, null)
    }, [props.transportCode])

    useEffect(() => {
        if (props.transport?.selectedWarehouseRecordForTransport?.id) {
            if(props.transport?.selectedWarehouseRecordForTransport?.withoutTransport === 1){
                setWithoutTransport(true)
            }else{
                setWithoutTransport(false)
            }
            let tempArray = []
            let tempObj = {
                ...props.transport?.selectedWarehouseRecordForTransport
            }
            let projectLocation = tempObj?.measureLocation?.split(',')
            tempArray.push({
                id: tempObj?.transport ? tempObj?.transport?.id : null,
                value: tempObj?.warehouseRecordId,
                label: `${tempObj?.label}, ${tempObj?.project?.name}, ${tempObj?.client?.clientName ? tempObj?.client?.clientName : ''}`,
                location: projectLocation?.length === 2 ? projectLocation : [],
                transportDetails: '',
                comment: '',
                orderAcceptanceDate: tempObj?.orderAcceptanceDate || '',
                orderArrivalDate: tempObj?.orderArrivalDate || '',
                warehouseRecordFile: tempObj?.warehouse?.invoiceWithUrl,
                warehouseRecordId: tempObj?.warehouseRecordId,
                projectId: tempObj?.project?.id,
                productWarehouseRecord: tempObj?.productWarehouseRecord
            })
            setSelectedProjects(tempArray)
        }
    }, [props.transport?.selectedWarehouseRecordForTransport])

    useEffect(() => {
        let tempArray = []
        props.transport?.projectsForTransport?.forEach((record) => {
            tempArray.push({
                value: record?.warehouseRecordId,
                label: `${record?.label}, ${record?.project?.name}, ${record?.clientName}`,
                location: record?.measureLocation ? record?.measureLocation?.split(',') : '',
                products: record?.productWarehouseRecord,
                warehouseRecordFile: record?.invoiceWithUrl,
                warehouseRecordId: record?.warehouseRecordId,
                projectId: record?.id,
                productWarehouseRecord: record?.productWarehouseRecord
            })
        })
        setProjects(tempArray)
    }, [props.transport?.projectsForTransport])

    useEffect(() => {
        if (props.transport.transportRecordsByCode && props.transportCode) {
            let tempArray = []
            props.transport.transportRecordsByCode?.forEach((transportRecord) => {
                let projectLocation = transportRecord?.projects?.measureLocation?.split(',')
                tempArray.push({
                    id: transportRecord?.id,
                    value: transportRecord?.warehouse?.id,
                    label: `${transportRecord?.warehouse?.label}, ${transportRecord?.project?.label}, ${transportRecord?.client?.clientName ? transportRecord?.client?.clientName : ''}`,
                    location: projectLocation?.length === 2 ? projectLocation : [],
                    transportDetails: transportRecord?.transportDetails,
                    comment: transportRecord?.comment,
                    orderAcceptanceDate: transportRecord?.orderAcceptanceDate || '',
                    orderArrivalDate: transportRecord?.orderArrivalDate || '',
                    warehouseRecordFile: transportRecord?.warehouse?.invoiceWithUrl,
                    warehouseRecordId: transportRecord?.warehouse?.id,
                    projectId: transportRecord?.project?.value,
                    productWarehouseRecord: transportRecord?.warehouse?.productWarehouseRecord
                })
            })
            setSelectedProjects(tempArray)
        }
    }, [props.transport.transportRecordsByCode])


    useEffect(() => {
        if (selectedProjects.length !== 0) {
            let formData = new FormData()

            selectedProjects?.forEach((project) => {
                formData.append('projectId[]', project.projectId)
            })
            props?.transportActions?.fetchTransportEquipments(formData)
        } else {
            setNeededEquipments([])
        }

        let tempArray = []
        selectedProjects?.forEach((project) => {
            tempArray.push({
                ...project,
                orderAcceptanceDate: project?.orderAcceptanceDate ? project?.orderAcceptanceDate : moment().format('YYYY-MM-DD HH:mm:ss')
            })
        })
        setTransportRecords(tempArray)
    }, [selectedProjects])

    useEffect(() => {
        setNeededEquipments(props?.transport?.transportEquipments)
    }, [props?.transport?.transportEquipments])

    useEffect(() => {
        if (neededEquipments) {
            let tempArray = []
            neededEquipments?.forEach(() => {
                tempArray.push(props?.transportCode ? true : false)
            })
            setAllEquipmentsTaken(props?.transportCode ? true : false)
            setCheckedEquipments(tempArray)
        } else {
            setCheckedEquipments([])
        }
    }, [neededEquipments])

    function getCurrentPosition() {
        navigator.geolocation.getCurrentPosition(function (geoLocationPosition) {
            if (geoLocationPosition?.coords?.latitude && geoLocationPosition?.coords?.longitude) {
                setPosition([geoLocationPosition.coords.latitude, geoLocationPosition.coords.longitude])
            }
        }, (err) => {
            console.warn(`ERROR(${err.code}): ${err.message}`)
        }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 60000,
        });
    }

    function resetFields() {
        setSelectedProjects([])
        props.history.push('/dashboard/transport/create/record')
    }

    function clearDate(which, index) {
        let tempArray = [
            ...transportRecords
        ]
        tempArray[index] = {
            ...tempArray[index],
            [which]: ''
        }
        setTransportRecords(tempArray)
    }

    function getCurrentDate(which, index) {
        let tempArray = [
            ...transportRecords
        ]
        let currentDate = moment().format("YYYY-MM-DD HH:mm:ss")
        tempArray[index] = {
            ...tempArray[index],
            [which]: currentDate
        }
        setTransportRecords(tempArray)
    }

    function toggleAllEquipments(checked) {
        let tempArray = []
        checkedEquipments && checkedEquipments.forEach(() => {
            tempArray.push(checked)
        })
        setAllEquipmentsTaken(checked)
        setCheckedEquipments(tempArray)
    }

    function saveCheckedEquipments(checked, value, index) {
        let tempArray = [...checkedEquipments]
        tempArray[index] = checked
        setCheckedEquipments(tempArray)
    }

    function saveTransport() {

        let anyCreationsLeft = false

        transportRecords?.forEach(record => {
            if (!record.id) {
                anyCreationsLeft = true
            }
        })

        if (anyCreationsLeft) {
            if (selectedProjects.length === 0) {
                toast.error('Zgjidhni projektet per transport!')
                return
            }
            let valid = true
            if (neededEquipments.length !== 0) {
                checkedEquipments?.forEach((check) => {
                    if (!check) {
                        valid = false
                    }
                })
            }

            if (!valid) {
                toast.error('Duhet te merrni te gjitha pajisjet e kerkuara!')
                return;
            }
            let index = 0
            let formData = new FormData()
            transportRecords?.forEach((record) => {
                if (!record?.id) {
                    formData.append(`projectId[${index}]`, record?.projectId)
                    formData.append(`warehouseRecordId[${index}]`, record?.warehouseRecordId)
                    if (record?.transportDetails) {
                        formData.append(`transportDetails[${index}]`, record?.transportDetails)
                    }
                    if (record?.email) {
                        formData.append(`email[${index}]`, record?.email)
                    }
                    if (record?.name) {
                        formData.append(`name[${index}]`, record?.name)
                    }
                    if (record?.phoneNumber) {
                        formData.append(`phoneNumber[${index}]`, record?.phoneNumber)
                    }
                    if (record?.orderArrivalDate) {
                        formData.append(`arrivalDate[${index}]`, record?.orderArrivalDate)
                    }
                    if (record?.orderAcceptanceDate) {
                        formData.append(`acceptanceDate[${index}]`, record?.orderAcceptanceDate)
                    }
                    if (record?.comment) {
                        formData.append(`comment[${index}]`, record?.comment)
                    }
                    if (props?.transportCode) {
                        formData.append(`code[${index}]`, props?.transportCode);
                    }
                    index += 1
                }
            })
            props.transportActions.createTransportRecord(formData).then(() => {
                toast.success('Rekordi u krijua me sukses!')
                props.history.push('/dashboard/transport')
            })
        } else {
            toast.error('Nuk keni projekte te reja per te ruajtur!')
        }
    }

    function setAllTransportRecords(name, value, index) {
        let tempArray = [
            ...transportRecords
        ]

        tempArray[index] = {
            ...tempArray[index],
            [name]: value
        }

        setTransportRecords(tempArray)
    }

    function editThisTransportRecord(record) {
        if (!record?.orderAcceptanceDate) {
            toast.error('Duhet te kete date te nisjes!')
            return
        }
        let formData = new FormData()
        if (record?.warehouseRecordId) {
            formData.append(`warehouseRecordId`, record?.warehouseRecordId)
        }
        if (record?.transportDetails) {
            formData.append(`transportDetails`, record?.transportDetails)
        }
        if (record?.orderArrivalDate) {
            formData.append(`arrivalDate`, record?.orderArrivalDate)
        }
        if (record?.orderAcceptanceDate) {
            formData.append(`acceptanceDate`, record?.orderAcceptanceDate)
        }
        if (record?.comment) {
            formData.append(`comment`, record?.comment)
        }

        props.transportActions.editTransportRecord(formData, record.id).then(() => {
            toast.success('Transporti u editua me sukses!')
        })
    }

    function toggleMap() {
        setShowMap(!showMap)
    }

    function createTransportForm() {
        return (
            <>
                <div className={`w-100 mb-5 transportContainer ${isMobile ? "flex-column" : ""}`}>
                    <div className={`formWrapper ${isMobile ? 'w-100' : ''}`}>
                        {(!withoutTransport) && <div className={'projectMultiSelectContainer'}>
                            <Select
                                id={`projectMultiSelect`}
                                name="projectMultiSelect"
                                options={projects}
                                closeMenuOnSelect={false}
                                className={'assigneeSelect'}
                                classNamePrefix="react-select"
                                isMulti
                                value={selectedProjects || ''}
                                onChange={(value) => setSelectedProjects(value)}
                                placeholder={translatedText({
                                    id: "client.order",
                                    defaultMessage: "Projektet qe doni ti zgjidhni per transport"
                                })}/>
                        </div>}
                        <div className={'transportFormContainer d-flex justify-content-between'}>
                            <div className="transportFormContainer-left">
                                {transportRecords && transportRecords.map((project, index) => {
                                    return <div
                                        className={`projectFormForTransport ${transportRecords[index]?.orderAcceptanceDate && transportRecords[index]?.orderArrivalDate ? 'alert alert-success' : ''}`}
                                        key={index}>
                                        <div className={'d-flex'}>
                                            <h3 className={'title'}>{project?.label}{transportRecords[index]?.orderAcceptanceDate && transportRecords[index]?.orderArrivalDate &&
                                            <Tooltip placement="right"
                                                     overlay={<span><TranslatedText id="app.clearForm"
                                                                                    defaultMessage="Transporti u krye me sukses"/></span>}>
                                                <FontAwesome name={'check'}/>
                                            </Tooltip>}</h3>
                                        </div>
                                        <div className={'fileContainer'}>
                                            {project?.warehouseRecordFile &&
                                            <a target={'_blank'} href={project?.warehouseRecordFile}>
                                                <div className={'fileIcon'}>
                                                    <Tooltip placement="right"
                                                             overlay={<span><TranslatedText id="app.clearForm"
                                                                                            defaultMessage="Fletdergesa"/></span>}>
                                                        <FontAwesome name="file"/>
                                                    </Tooltip>
                                                </div>
                                            </a>}
                                            {project?.location && <Tooltip placement="right"
                                                                           overlay={<TranslatedText id="app.clearForm"
                                                                                                    defaultMessage="Linku per ne harte"/>}>
                                                <a target={'_blank'}
                                                    // href={`https://www.google.com/maps/?q=${project?.location[0]},${project?.location[1]}`}
                                                   href={`https://www.google.com/maps/dir/${position[0]},${position[1]}/${project?.location[0]},${project?.location[1]}/@${position[0]},${position[1]}?entry=ttu`}
                                                   className={'mapIcon'}><FontAwesome name={'map'}/></a>
                                            </Tooltip>}
                                        </div>
                                        <div className={'w-100 d-flex flex-wrap transportDateInputWrapper'}>
                                            <div className={'warehouseProductsContainer'}>
                                                <div className={'warehouseProductRow bold'}>
                                                    <p>Produkti</p>
                                                    <p>Sasia</p>
                                                </div>
                                                {project?.productWarehouseRecord?.map((warehouseProduct) => {
                                                    return <div className={'warehouseProductRow'}>
                                                        <p>{warehouseProduct?.productName}</p>
                                                        <p>{warehouseProduct?.quantityForTransport}</p></div>
                                                })}
                                            </div>
                                            {(!withoutTransport) && <div className={isMobile ? "w-100" : 'w-50 pr-12'}>
                                                <Input
                                                    name="orderAcceptanceDate"
                                                    label="label"
                                                    id="orderAcceptanceDate"
                                                    inputWrapperClass={"w-100"}
                                                    type="text"
                                                    inputTextType={EInputTextType.SimpleInput}
                                                    value={transportRecords[index]?.orderAcceptanceDate ? moment(transportRecords[index]?.orderAcceptanceDate).format('DD-MM-YYYY HH:mm:ss') : ''}
                                                    disabled
                                                >
                                                    <p className={'m-0'}>Data e fillimit te transportit</p>
                                                    <div className={'clearDate'}>
                                                        <p className={'x-sign'}
                                                           onClick={() => clearDate('orderAcceptanceDate', index)}>+</p>
                                                    </div>
                                                    {((!props.permissions.observer) || props.permissions.fullPrivileges) &&
                                                    <Tooltip placement="right"
                                                             overlay={<span>Ruaj kohen</span>}>
                                                        <img src={clock} alt=""
                                                             className="pinLogoForMeasure"
                                                             onClick={() => getCurrentDate('orderAcceptanceDate', index)}/>
                                                    </Tooltip>}
                                                </Input>
                                            </div>}
                                            {(!withoutTransport) && <div className={isMobile ? "w-100" : 'w-50 pr-12'}>
                                                <Input
                                                    name="orderAcceptationDate"
                                                    label="label"
                                                    id="orderAcceptationDate"
                                                    inputWrapperClass={"w-100"}
                                                    type="text"
                                                    inputTextType={EInputTextType.SimpleInput}
                                                    value={transportRecords[index]?.orderArrivalDate ? moment(transportRecords[index]?.orderArrivalDate).format('DD-MM-YYYY HH:mm:ss') : ''}
                                                    disabled
                                                >
                                                    <p className={'m-0'}>Data e arritjes te transportit</p>
                                                    <div className={'clearDate'}>
                                                        <p className={'x-sign'}
                                                           onClick={() => clearDate('orderArrivalDate', index)}>+</p>
                                                    </div>
                                                    {((!props.permissions.observer) || props.permissions.fullPrivileges) &&
                                                    <Tooltip placement="right"
                                                             overlay={<span>Ruaj kohen</span>}>
                                                        <img src={clock} alt=""
                                                             className="pinLogoForMeasure"
                                                             onClick={() => getCurrentDate('orderArrivalDate', index)}/>
                                                    </Tooltip>}
                                                </Input>
                                            </div>}
                                        </div>
                                        {transportRecords[index]?.orderAcceptanceDate && transportRecords[index]?.orderArrivalDate &&
                                        <div>
                                            {(!withoutTransport) && <TimeTracker
                                                seconds={(new Date(transportRecords[index]?.orderArrivalDate).getTime() - new Date(transportRecords[index]?.orderAcceptanceDate).getTime()) / 1000}
                                                title={translatedText({
                                                    id: "app.waitingTime",
                                                    defaultMessage: "Koha e transportit"
                                                })}/>}
                                        </div>
                                        }
                                        {withoutTransport && <div className={'w-100 d-flex flex-wrap'}>
                                            <div className="w-100">
                                                <Input
                                                    name="name"
                                                    label="label"
                                                    id="name"
                                                    inputWrapperClass={"w-100"}
                                                    type="text"
                                                    inputTextType={EInputTextType.SimpleInput}
                                                    value={transportRecords[index] ? transportRecords[index]?.name : ''}
                                                    onChange={(name, value) => setAllTransportRecords(name, value, index)}
                                                >
                                                    <TranslatedText id="app.agreements.appendFinalAgreement"
                                                                    defaultMessage="Pergjegjesi i transportit"/>
                                                </Input>
                                            </div>
                                            <div className="w-50 pr-12">
                                                <Input
                                                    name="email"
                                                    label="label"
                                                    id="email"
                                                    inputWrapperClass={"w-100"}
                                                    type="text"
                                                    inputTextType={EInputTextType.SimpleInput}
                                                    value={transportRecords[index] ? transportRecords[index]?.email : ''}
                                                    onChange={(name, value) => setAllTransportRecords(name, value, index)}
                                                >
                                                    <TranslatedText id="app.agreements.appendFinalAgreement"
                                                                    defaultMessage="Email"/>
                                                </Input>
                                            </div>
                                            <div className="w-50 pl-12">
                                                <Input
                                                    name="phoneNumber"
                                                    label="label"
                                                    id="phoneNumber"
                                                    inputWrapperClass={"w-100"}
                                                    type="text"
                                                    inputTextType={EInputTextType.SimpleInput}
                                                    value={transportRecords[index] ? transportRecords[index]?.phoneNumber : ''}
                                                    onChange={(name, value) => setAllTransportRecords(name, value, index)}
                                                >
                                                    <TranslatedText id="app.agreements.appendFinalAgreement"
                                                                    defaultMessage="Numri i telefonit"/>
                                                </Input>
                                            </div>
                                        </div>}
                                        <Input
                                            name="transportDetails"
                                            label="label"
                                            id="transportDetails"
                                            inputWrapperClass={"w-100"}
                                            type="textarea"
                                            value={transportRecords[index] ? transportRecords[index]?.transportDetails : ''}
                                            onChange={(name, value) => setAllTransportRecords(name, value, index)}
                                        >
                                            <TranslatedText id="app.agreements.appendFinalAgreement"
                                                            defaultMessage="Detajet e transportit"/>
                                        </Input>
                                        {transportRecords[index] && transportRecords[index].id &&
                                        <div className={'d-flex justify-content-end'}>
                                            <Button
                                                isLoading={props?.buttonLoader}
                                                isDisabled={props?.buttonLoader}
                                                onClick={() => editThisTransportRecord(transportRecords[index])}
                                                className="m-0 p-2"
                                                buttonType={EButtonType.PurpleButton}>
                                                <TranslatedText id="client.editContact"
                                                                defaultMessage="Ruaj"/>
                                            </Button>
                                        </div>}
                                    </div>
                                })}
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-between">
                            <Button
                                isLoading={props?.buttonLoader}
                                isDisabled={props?.buttonLoader}
                                className={'w-25'}
                                onClick={toggleMap}
                                buttonType={EButtonType.PurpleButton}>
                                {showMap ? <TranslatedText id="client.saveContact" defaultMessage="Mbyll harten"/> :
                                    <TranslatedText id="client.saveContact" defaultMessage="Hap harten"/>}
                            </Button>
                        </div>
                    </div>
                    {selectedProjects.length !== 0 &&
                    <div className="transportFormContainer-right">
                        <div className="equipmentsCheckboxContainer">
                            <div className="transportEquipmentListHeader w-100">
                                <div className="titleContainer w-100">
                                    <p>Lista e pajisjeve te nevojitura</p>
                                </div>
                            </div>
                            {neededEquipments?.map((equipment, index) => {
                                return (
                                    <div key={index} className="transportEquipmentListContent w-100 d-flex">
                                        <div className="w-100 d-flex justify-content-between">
                                            <p>{equipment?.label}</p>
                                            <Input
                                                value={equipment?.value}
                                                type="checkbox"
                                                valueNeedeed
                                                checked={checkedEquipments[index]}
                                                onChange={(currentTarget) => saveCheckedEquipments(currentTarget.checked, currentTarget.value, index)}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                            <div className={'d-flex justify-content-between align-items-center'}>
                                <p className={'m-0'}>I kam te gjitha</p>
                                <Input
                                    value={'checkAll'}
                                    type="checkbox"
                                    inputWrapperClass={'m-0 d-flex align-items-center'}
                                    valueNeedeed
                                    checked={allEquipmentsTaken}
                                    onChange={(currentTarget) => toggleAllEquipments(currentTarget.checked)}
                                />
                            </div>
                        </div>
                        <Button
                            isLoading={props?.buttonLoader}
                            isDisabled={props?.buttonLoader}
                            className={'transportSaveButton'}
                            onClick={saveTransport}
                            buttonType={EButtonType.PurpleButton}>
                            <TranslatedText id="client.saveContact" defaultMessage="Ruaj"/>
                        </Button>
                    </div>}
                </div>
            </>
        )
    }

    let icon = L.icon({
        iconUrl: leafletMapIconBase64,
        iconSize: [25, 41],
        iconAnchor: [12.5, 41],
        popupAnchor: [0, -41]
    })

    return (
        <div className="wrapper w-100">
            {isMobile && <h1 className={'p-3'}>Transport</h1>}
            {props.loading ? <Spinner/> : createTransportForm()}
            {showMap && <div className={'mapAndEquipmentsContainer'}>
                {position && <div className="mapContainer mobile">
                    <MapContainer
                        center={position}
                        zoom={10}
                        scrollWheelZoom={false}
                        className={'map'}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {selectedProjects && selectedProjects?.map((project) => {
                            return (<>
                                {project?.location?.length === 2 && <Marker
                                    position={project?.location}
                                    icon={icon}
                                >
                                    <Popup>
                                        <p className={'mb-0'}>{project?.label}</p>
                                        {project?.location[0] && <a target={'_blank'}
                                            // href={`https://www.google.com/maps/?q=${project?.location[0]},${project?.location[1]}`}
                                                                    href={`https://www.google.com/maps/dir/${position[0]},${position[1]}/${project?.location[0]},${project?.location[1]}/@${position[0]},${position[1]},15z?entry=ttu`}

                                                                    className={'mb-0 googleMapsLink'}>Shiko ne Google
                                            maps</a>}
                                    </Popup>
                                </Marker>}
                            </>)
                        })}
                    </MapContainer>
                </div>}
            </div>}
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        user: state.user,
        transport: state.transport
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateContactForm as any)))