import * as React from 'react'
import './UserAvatar.scss'
import ChatIcon from '../../assets/icons/chat-icon.svg'
import NotificationIcon from '../../assets/icons/notification.svg'
import HelpIcon from '../../assets/icons/help-circle.svg'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RootState } from '../../reducers'
import { Dispatch, bindActionCreators } from 'redux'
import * as actions from '../../actions/generalActions'
import { useState } from 'react'
import { useOutsideAlerter } from '../OutsideAlerter/OutsideAlerter'
import { ReactComponent as LogOut } from '../../assets/icons/sign-out.svg'
import { useHistory } from 'react-router'
import MikaSelect from "../BbrosSelect/mikaSelect.component";
import {SelectOption} from "../../types/selectOption.type";
import {setLanguage} from "../../actions/generalActions";
import TranslatedText from "../TranslatedText";

interface Props {
    name?: string;
    img?: any;
    avatar?: any;
    logOut: any;
    actions: any;
    permissions: any
    isNavOpen?: boolean
    language: SelectOption
}

interface State {

}


const UserAvatar = (props:Props) => {
    const {visible, setVisible, ref } = useOutsideAlerter(false)
    const [dropdown, setDropdown] = useState(false)
    const history = useHistory();

    const goToAdminPanel = () => {
        props.actions.openAdminPanel(true)
        history.push('/admin')
        setVisible(false)
    }

    const dropdownItems = () => {
        return (
            <div className={`dropdown-items ${props?.isNavOpen ? 'd-none' : ''}`}>
                <ul>
                    { props.permissions?.fullPrivileges && <li onClick={goToAdminPanel}>
                        <TranslatedText id="login.settings" defaultMessage="Konfigurimet"/>
                    </li>}
                    <li onClick={props.logOut}>
                        <TranslatedText id="login.logOut" defaultMessage="Dil" />
                        <LogOut className="logout-icon" />
                    </li>
                </ul>
            </div>
        )
    }
    
    const handleClick = () => {
        setVisible(() => !visible)
    }

    const {
        name,
        img,
        avatar
    } = props
    return (
        <div className={`user-avatar d-flex ${props?.isNavOpen ? 'd-none' : ''}`}>
            <div className="notifications d-flex align-items-center">
                <div className="position-relative">
                    <div className="justify-content-between name-avatar d-flex align-items-center" onClick={() => handleClick()}>
                        <div className="avatar">
                            <img className="avatar-img" src={avatar} alt="avatar" />
                        </div>
                        <p className="name mb-0">
                            {name}
                        </p>
                    </div>
                    {visible && <div ref={ref}> {dropdownItems()} </div>}
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(UserAvatar);