import React, { useEffect, useState } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import { withRouter } from 'react-router';
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import L, {LatLngExpression} from "leaflet";
import {
    leafletMapIconBase64,
    leafletMapIconBase64Green,
    leafletMapIconBase64Yellow,
    statusIcons
} from "../../constants/constants";
import * as serviceActions from "../../actions/service";


interface Props {
    intl: any;
    actions: any;
    clientActions: any;
    client: any;
    letter: string;
    history:any;
    loading: boolean;
    archiveRecords: any;
    serviceActions: any
    service: any
}

const AllProjectLocationMap = (props: Props) => {

    const [position, setPosition] = useState<LatLngExpression>()
    const [projectLocations, setProjectLocations] = useState<any>()
    const [projectLocationsService, setProjectLocationsService] = useState<any>()
    const [windowSize, setWindowSize] = useState<any>(window.innerWidth)

    useEffect(()=>{
        getCurrentPosition()
        props.actions.getArchiveRecords(`archive/warehouse?transportMap=1`)
        props.serviceActions.getServiceMapData()
        window.addEventListener('resize', changeWindowSize);
    }, [])

    function changeWindowSize(e){
        setWindowSize(null)
        setWindowSize(window.innerWidth)
        console.log(window.innerWidth, "window.innerWidth")
        setPosition(null)
        getCurrentPosition()
    }

    useEffect(()=>{
        console.log(props?.service?.serviceMapData,'props?.service?.serviceMapData')
        if(props?.service?.serviceMapData){
            let tempArray = []
            props?.service?.serviceMapData?.forEach((record)=>{
                if(record?.measureLocation){
                    let latLngArray = record?.measureLocation ? record?.measureLocation?.split(',') : record?.projectLocation.split(',');
                    if(latLngArray?.length === 2){
                        tempArray.push({
                            projectId: record?.project?.id,
                            projectName: record?.project?.name,
                            coordinates: [parseFloat(latLngArray[0]), parseFloat(latLngArray[1])],
                            status: record?.status
                        })
                    }
                }
            })
            setProjectLocationsService(tempArray)
        }
    },[props?.service?.serviceMapData])

    useEffect(()=>{
        if(props?.archiveRecords){
            let tempArray = []
            props?.archiveRecords?.forEach((record)=>{
                //status 1 for default
                //status 2 for yellow
                //status 3 for green
                let status = 1
                if(record?.transport?.orderAcceptanceDate && !record?.transport?.orderArrivalDate){
                    status = 2
                }else if(record?.transport?.orderAcceptanceDate && record?.transport?.orderArrivalDate){
                    status = 3
                }
                let latLngArray = record?.measureLocation ? record?.measureLocation?.split(',') : record?.project?.projectLocation?.split(',');
                if(latLngArray?.length === 2){
                    console.log(status,'statusservice')
                    tempArray.push({
                        projectId: record?.project?.id,
                        projectName: record?.project?.projectName,
                        coordinates: [parseFloat(latLngArray[0]), parseFloat(latLngArray[1])],
                        status: status
                    })
                }
            })
            setProjectLocations(tempArray)
        }
    },[props?.archiveRecords])

    function getCurrentPosition() {
        navigator.geolocation.getCurrentPosition(function (geoLocationPosition) {
            if (geoLocationPosition?.coords?.latitude && geoLocationPosition?.coords?.longitude) {
                console.log(geoLocationPosition.coords.latitude, geoLocationPosition.coords.longitude,'geoLocationPosition.coords.latitude, geoLocationPosition.coords.longitude')
                setPosition([geoLocationPosition.coords.latitude, geoLocationPosition.coords.longitude])
            }
        },(err)=> {
            console.warn(`ERROR(${err.code}): ${err.message}`)
        }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 60000,
        });
    }

    let icon = L.icon({
        iconUrl: leafletMapIconBase64,
        iconSize: [25, 41],
        iconAnchor: [12.5, 41],
        popupAnchor: [0, -41]
    })

    let iconYellow = L.icon({
        iconUrl: leafletMapIconBase64Yellow,
        iconSize: [25, 41],
        iconAnchor: [12.5, 41],
        popupAnchor: [0, -41]
    })

    let iconGreen = L.icon({
        iconUrl: leafletMapIconBase64Green,
        iconSize: [25, 41],
        iconAnchor: [12.5, 41],
        popupAnchor: [0, -41]
    })

    return (
        <div className="transportContainer">
            {windowSize && position && <div className="mapContainer w-100 h-100">
                <MapContainer
                    center={position}
                    zoom={10}
                    scrollWheelZoom={true}
                    className={'map'}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {projectLocations && projectLocations?.map((projectLocation, index)=>{
                        let customIcon

                        if(projectLocation?.status === 3){
                            customIcon = iconGreen
                        }else if(projectLocation?.status === 2){
                            customIcon = iconYellow
                        }else{
                            customIcon = icon
                        }
                        return (<Marker
                            key={index}
                            position={projectLocation?.coordinates}
                            icon={customIcon}
                        >
                            <Popup>
                                <p className={'mb-0'}>{projectLocation?.projectName}</p>
                                {projectLocation?.coordinates[0] && <a target={'_blank'}
                                                                       href={`https://www.google.com/maps/?q=${projectLocation?.coordinates[0]},${projectLocation?.coordinates[1]}`}
                                                                       className={'mb-0 googleMapsLink'}>Shiko ne Google maps</a>}
                            </Popup>
                        </Marker>)
                    })}
                    {projectLocationsService && projectLocationsService?.map((projectLocation, index)=>{
                        console.log(projectLocation,JSON.stringify(projectLocation?.status),'service')
                        return (<Marker
                            key={index}
                            position={projectLocation?.coordinates}
                            icon={projectLocation?.status?.value ? statusIcons[projectLocation?.status?.value] : icon }
                        >
                            <Popup>
                                <p className={'mb-0'}>{projectLocation?.projectName}</p>
                                <p className={'mb-0'}>{projectLocation?.status?.label}</p>
                                {projectLocation?.coordinates[0] && <a target={'_blank'}
                                                                       href={`https://www.google.com/maps/dir/${position[0]},${position[1]}/${projectLocation?.coordinates[0]},${projectLocation?.coordinates[1]}/@${position[0]},${position[1]},15z?entry=ttu`}
                                                                       className={'mb-0 googleMapsLink'}>Shiko ne Google maps</a>}
                            </Popup>
                        </Marker>)
                    })}
                </MapContainer>
            </div>}
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        user: state.user,
        service: state.service,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        serviceActions: bindActionCreators(serviceActions as any, dispatch),

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(AllProjectLocationMap as any)))