import React, { useEffect, useState } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as installationActions from '../../actions/installation';
import * as serviceActions from '../../actions/service';
import { withRouter } from 'react-router';
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import L, {LatLngExpression} from "leaflet";
import {getServiceMapData} from "../../actions/service";
import {
    complicatedService,
    leafletMapIconBase64,
    serviceControl,
    simpleService,
    statusIcons
} from "../../constants/constants";


interface Props {
    intl: any;
    actions: any;
    clientActions: any;
    client: any;
    letter: string;
    history:any;
    loading: boolean;
    archiveRecords: any;
    installationActions: any
    installation: any
    serviceActions: any
    service: any
}

const ServiceMap = (props: Props) => {

    const [position, setPosition] = useState<LatLngExpression>()
    const [projectLocations, setProjectLocations] = useState<any>()
    const [windowSize, setWindowSize] = useState<any>(window.innerWidth)

    useEffect(()=>{
        getCurrentPosition()
        props.serviceActions.getServiceMapData()
        window.addEventListener('resize', changeWindowSize);
    }, [])

    function changeWindowSize(e){
        setWindowSize(null)
        setWindowSize(window.innerWidth)
        console.log(window.innerWidth, "window.innerWidth")
        setPosition(null)
        getCurrentPosition()
    }

    useEffect(()=>{
            console.log(props?.service?.serviceMapData,'props?.service?.serviceMapData')
        if(props?.service?.serviceMapData){
            let tempArray = []
            props?.service?.serviceMapData?.forEach((record)=>{
                if(record?.measureLocation){
                    let latLngArray = record?.measureLocation ? record?.measureLocation?.split(',') : record?.projectLocation.split(',');
                    if(latLngArray?.length === 2){
                        tempArray.push({
                            projectId: record?.project?.id,
                            projectName: record?.project?.name,
                            coordinates: [parseFloat(latLngArray[0]), parseFloat(latLngArray[1])],
                            status: record?.status
                        })
                    }
                }
            })
            setProjectLocations(tempArray)
        }
    },[props?.service?.serviceMapData])

    function getCurrentPosition() {
        navigator.geolocation.getCurrentPosition(function (geoLocationPosition) {
            if (geoLocationPosition?.coords?.latitude && geoLocationPosition?.coords?.longitude) {
                setPosition([geoLocationPosition.coords.latitude, geoLocationPosition.coords.longitude])
            }
        },(err)=> {
            console.warn(`ERROR(${err.code}): ${err.message}`)
        }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 60000,
        });
    }

    let icon = L.icon({
        iconUrl: leafletMapIconBase64,
        iconSize: [25, 41],
        iconAnchor: [12.5, 41],
        popupAnchor: [0, -41]
    })



    // @ts-ignore
    return (
        <div className="transportContainer">
            {windowSize && position && <div className="mapContainer w-100 h-100">
                <MapContainer
                    center={position}
                    zoom={10}
                    scrollWheelZoom={true}
                    className={'map'}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {projectLocations && projectLocations?.map((projectLocation, index)=>{
                        console.log(projectLocation,JSON.stringify(projectLocation?.status?.value),'service')

                        return (<Marker
                            key={index}
                            position={projectLocation?.coordinates}
                            icon={projectLocation?.status?.value ? statusIcons[projectLocation?.status?.value] : icon }
                        >
                            <Popup>
                                <p className={'mb-0'}>{projectLocation?.projectName}</p>
                                <p className={'mb-0'}>{projectLocation?.status?.label}</p>
                                {projectLocation?.coordinates[0] && <a target={'_blank'}
                                                                       href={`https://www.google.com/maps/dir/${position[0]},${position[1]}/${projectLocation?.coordinates[0]},${projectLocation?.coordinates[1]}/@${position[0]},${position[1]},15z?entry=ttu`}
                                                                       className={'mb-0 googleMapsLink'}>Shiko ne Google maps</a>}
                            </Popup>
                        </Marker>)
                    })}
                </MapContainer>
            </div>}
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        user: state.user,
        service: state.service
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        installationActions: bindActionCreators(installationActions as any, dispatch),
        serviceActions: bindActionCreators(serviceActions as any, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ServiceMap as any)))