import {EInputTextType, Input} from '../../../components/Input/Input';
import {OfferProduct} from '../../../types/offerProduct.type';
import React, {useEffect, useState} from 'react';
import './calculateOfferModal.scss';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router';
import {RootState} from '../../../reducers';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import * as actions from '../../../actions/generalActions';
import * as offerActions from '../../../actions/offers';
import * as adminActions from '../../../actions/admin';
import DifferentColoredPlus from '../../../assets/icons/differentColoredPlus.svg';
import MikaSelect from '../../../components/BbrosSelect/mikaSelect.component';
import {ArticleInfomation} from '../../../types/ArticleInformation.type';
import {axiosCaller} from '../../../utils/apiCaller';
import {SelectOption} from '../../../types/selectOption.type';
import {Button} from '../../../components/Button'
import {toast} from "react-toastify";
import {formatNumberPrice, toFixedNumber} from '../../../utils/react';
import {formatDate} from '../../../utils/variableEvaluation';
import clearFormSvg from "../../../assets/icons/archeology.svg";
import Tooltip from "rc-tooltip";
import DeleteModalComponent from "../../../components/Modal/DeleteModal";
import ModalComponent from "../../../components/Modal/ModalComponent";
import TranslatedText, {translatedText} from "../../../components/TranslatedText";
import FontAwesome from 'react-fontawesome';
import styles from "../../../components/Input/AssigneeBoxInput/AssigneeBoxInput.module.scss";
import {checkCorrespondingInput, setOnlyNewCorrespondingItems} from "../../Admin/globalFunctions";
import moment from "moment";

interface Props {
    data?: OfferProduct;
    offers: any;
    offerActions: any;
    indexWhereThisProductIsPlacedInTheArray: number,
    products: OfferProduct[];
    setProducts?: (value: OfferProduct[] | ((prevVar: OfferProduct[]) => OfferProduct[])) => void;
    articles: ArticleInfomation[];
    intl: any;
    adminActions: any;
    admin: any;
    client: any;
    getProductsOfClient?: () => void,
    setUpdateDatesPayload?: (any) => void
    discount?: number,
    history?: any,
    permissions: any,
    offerId: any
}

const Product = (props: Props) => {

    const [product, setProduct] = useState<any>({} as any);
    const [calculatedTotalProductPrice, setCalculatedTotalProductPrice] = useState<number>(0);
    const [productTaxPrice, setProductTaxPrice] = useState<number>(0);
    const [selectedUnitName, setSelectedUnitName] = useState<number>();
    const [selectedWarehouseQuantity, setSelectedWarehouseQuantity] = useState<number>();
    const [selectedUnitId, setSelectedUnitId] = useState<number>();
    const [productCode, setProductCode] = useState<string>();
    const [articleId, setArticleId] = useState<number>();
    const [forProduction, setForProduction] = useState<boolean>(true)
    const [forProductionInnerStock, setForProductionInnerStock] = useState<boolean>(true)
    const [buttonLoader, setButtonLoader] = useState<boolean>(false)
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);
    const [isCancelModalOpen, setCancelModal] = useState<boolean>(false);
    const [selectedProductIndex, setSelectedProductIndex] = useState<number>(null);

    useEffect(() => {
        setProduct({
            ...props.data,
        });
    }, []);

    useEffect(() => {
        if (props?.data?.type) {
            setProductCode(props?.data?.type);
        }
        if (product?.designation?.value) {
            axiosCaller('GET', `articles/${product?.designation?.value}/responsibilities`).then((res) => {
                setSelectedUnitName(res.data?.data?.unit?.name);
                setSelectedUnitId(res.data?.data?.unit?.id);
                setSelectedWarehouseQuantity(res.data?.data?.warehouse?.quantity)
                if (!product?.type) {
                    setProductCode(res.data?.data?.productCode);
                }
            });
        }
    }, [product?.designation])

    const handleChange = (name, value) => {
        setProduct({
            ...product,
            totalPrice: calculatedTotalProductPrice,
            taxPrice: productTaxPrice,
            [name]: value,
        });
    };

    const removeProduct = (index: number) => {
        setSelectedProductIndex(index)
        setDeleteModal(true)
    };

    const selectProductForCancellation = (index: number) => {
        setSelectedProductIndex(index)
        setCancelModal(true)
    };

    const deleteProduct = () => {
        let productsCopy = [...props.products];
        productsCopy.splice(selectedProductIndex, 1);
        props.setProducts(productsCopy);
        if (product?.id) {
            axiosCaller('DELETE', `products/${product.id}`).then((res) => {
                if (res?.data?.finishedProduct) {
                    toast.info('Produkti gjindet ne arkive, nuk mund te fshihet!')
                    return
                }
                if (res.data.data === true) {
                    toast.success('Produkti u fshi me sukses!')
                    setDeleteModal(false)
                    props.getProductsOfClient()
                }
            });
        }
    };

    const cancelProduct = () => {
        console.log(product, 'product')

        let payload = {
            canceled: 1,
            offerId: product?.offerId,
            clientId: product?.clientID,
            type: product?.type,
            designation: product?.designation?.value,
            quantity: product?.unit,
            price: product?.price,
            discount: product?.discount,
            tax: product?.tax,
            start: product?.start,
            end: product?.end
        }
        if (product?.id) {
            axiosCaller('POST', `products/${product.id}`, payload).then((res) => {
                if (res.data.data.id) {
                    toast.success('Produkti u anulua me sukses!')
                    setDeleteModal(false)
                    props.getProductsOfClient()
                    props.offerActions.fetchClientOffer(props.offerId)
                }
            });
        }
    };

    useEffect(() => {
        let allProductsCopy = [...props.products];
        let discountedPrice = Number(product.price) - (Number(product.price) * (Number(product.discount) / 100));
        let taxPrice = discountedPrice + (discountedPrice * (product.tax / 100));
        let totalProductPrice = Number(taxPrice) * Number(product.unit);
        setProductTaxPrice(taxPrice);
        setCalculatedTotalProductPrice(totalProductPrice);

        allProductsCopy[props.indexWhereThisProductIsPlacedInTheArray] = {
            ...product,
            totalPrice: totalProductPrice
        };
        props.setProducts(allProductsCopy);
    }, [product]);

    function saveArticleAndUnit(name: string, value: SelectOption) {
        axiosCaller('GET', `articles/${value?.value}/responsibilities`).then((res) => {
            setSelectedUnitName(res.data?.data?.unit?.name);
            setSelectedUnitId(res.data?.data?.unit?.id);
            // setProductCode(res.data?.data?.productCode);
            setArticleId(value?.value)
            setForProduction(res.data?.data?.forProduction)
            handleChange(name, value);
        });
    }

    function saveProduct() {
        let tempProductIdArray = []
        props?.products?.forEach((propsProduct) => {
            if (propsProduct?.id) {
                tempProductIdArray?.push(propsProduct?.id)
            }
        })
        if (!(product?.designation?.value && product?.unit)) {
            toast.info('Ju lutem mbushni te dhenat!')
            return
        }
        axiosCaller('POST', `products/agreements/start-end-date`, {
            quantity: product.unit,
            unitId: selectedUnitId,
            articleId: product.designation.value,
            productIds: tempProductIdArray
        }).then(res => {
            setProduct({
                ...product,
                end: product?.id ? product?.end : res.data.data?.end,
                start: product?.id ?  product?.start  : res.data.data?.start
            });
            let payload = {
                type: productCode,
                designation: product?.designation?.value,
                quantity: product?.unit,
                price: product?.price,
                tax: product?.tax,
                taxPrice: productTaxPrice,
                end: product?.id ? product?.end : res.data.data?.end,
                start: product?.id ?  product?.start  : res.data.data?.start,
                discount: product?.discount,
                clientId: props?.client?.client?.id,
                offerId: null,
                forProduction: forProductionInnerStock ? 1 : 0
            }
            if (props.offers?.offerClient?.id || props.offers?.offerClient?.id !== "undefined") {
                payload.offerId = props.offers?.offerClient?.id
            }
            if (product.id) {
                axiosCaller('POST', `products/${product.id}`, payload).then(() => {
                    toast.success("Produkti u editua me sukses")
                    setButtonLoader(false)
                }).catch(() => {
                    toast.error("Diqka nuk shkoi mire")
                })
            } else {
                axiosCaller('POST', `products`, payload).then((res) => {
                    if (res?.data?.data) {
                        setProduct(res?.data?.data)
                        props.setProducts([
                            ...props.products,
                            res?.data?.data
                        ])
                        toast.success("Produkti u ruajt me sukses")
                        setButtonLoader(false)
                    }
                }).catch(() => {
                    toast.error("Diqka nuk shkoi mire")
                })
            }
        });
        setButtonLoader(true)
    }

    function manuallyUpdateStartAndEndDates() {
        props.setUpdateDatesPayload(
            {
                startDate: product?.start,
                endDate: product?.end,
                articleId: product.designation?.value,
                productId: product?.id,
                quantity: product?.unit
            }
        )
    }

    useEffect(() => {
        if(selectedWarehouseQuantity && product.unit && product.unit > selectedWarehouseQuantity){
            setForProductionInnerStock(true)
        }
    }, [product.unit]);

    return (

        <div className="product-wrapper">
            <div>
                <div className="d-flex justify-content-between">
                    <p className="list-title">
                        <TranslatedText id="app.table.nameSurname" defaultMessage="Produkti"/>
                    </p>
                    <div className="x-sign"
                         onClick={() => removeProduct(props.indexWhereThisProductIsPlacedInTheArray)}>
                        {/*<img src={DifferentColoredPlus} alt="close"/>*/}
                        <Tooltip placement="left" overlay={<span>Fshi produktin</span>}>
                            <FontAwesome name={'trash'}/>
                        </Tooltip>
                    </div>
                    {(!product?.canceled) && <div className="cancel-sign"
                                                  onClick={() => selectProductForCancellation(props.indexWhereThisProductIsPlacedInTheArray)}>
                        <Tooltip placement="left" overlay={<span>Anulo produktin</span>}>
                            <img src={DifferentColoredPlus} alt="close"/>
                            {/*<FontAwesome name={'trash'}/>*/}
                        </Tooltip>
                    </div>}


                    {selectedWarehouseQuantity && !forProduction && <div className={'forProductionInnerStockCheck'}>
                        <Tooltip placement="left" overlay={<span>Per prodhim</span>}>
                            <div className="round-checkbox">
                                <input type={'checkbox'} checked={forProductionInnerStock}
                                       onChange={(e) => setForProductionInnerStock(e?.target?.checked)}/>
                            </div>
                        </Tooltip>
                    </div>}
                    {product?.canceled ? <div className={'canceledLabel'}>
                    <p><TranslatedText id="thisProductIsCanceled" defaultMessage="Ky produkt eshte i anuluar!"/></p>
                    </div> : ''}
                </div>
                <div className="input-container">
                    <Tooltip placement="right" overlay={<span>{product.designation?.label}</span>}>
                        <MikaSelect
                            selectWrapperClassName="designation-select max-width-input mobile"
                            value={product.designation}
                            placeholder={translatedText({id: 'client.chooseArticle', defaultMessage: 'Artikujt'})}
                            name="designation"
                            setValueFunction={(name, value) => saveArticleAndUnit(name, value)}
                            options={props.articles}
                            disabled={product.id && true}
                            isOfferModalProduct
                        >
                            <TranslatedText id="table.article" defaultMessage="Artikulli"/>
                        </MikaSelect>
                    </Tooltip>

                    <Input
                        name="productCode"
                        label="label"
                        id="productCode"
                        inputWrapperClass="offerInputWrapper smallerInput medium mobile"
                        value={productCode || ''}
                        onChange={(name, value) => setProductCode(value)}
                        // disabled
                        min={0}
                        defaultValue=""
                        type="text"
                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="table.nameSurname" defaultMessage="Kodi i produktit"/>
                    </Input>

                    <Input
                        name="productCode"
                        label="label"
                        id="productCode"
                        inputWrapperClass="offerInputWrapper smallerWidth mobile"
                        value={selectedUnitName || ''}
                        disabled
                        defaultValue=""
                        type="text"
                        min={0}
                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="table.nameSurname" defaultMessage="Njesia"/>
                    </Input>

                    <Input
                        name="unit"
                        label="label"
                        id="commentsInput"
                        inputWrapperClass="offerInputWrapper smallerInput smallerWidth position-relative mobile"
                        value={product.unit || ''}
                        onChange={(name, value) => handleChange(name, value)}
                        defaultValue=""
                        type="number"
                        min={0}

                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="table.nameSurname" defaultMessage="Sasia"/>
                        {selectedWarehouseQuantity &&
                        <p data-toggle="tooltip" data-placement="left" title="Sasia ne depo:"
                           className={'warehouseQuantity'}>Ne stok: {selectedWarehouseQuantity}</p>}
                    </Input>

                    <Input
                        name="price"
                        label="label"
                        id="commentsInput"
                        inputWrapperClass="offerInputWrapper smallerInput smallerWidth mobile"
                        value={formatNumberPrice(String(product.price), 2) || ''}
                        onChange={(name, value) => handleChange(name, value)}
                        defaultValue=""
                        min={0}

                        type="number"
                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="table.nameSurname" defaultMessage="Cmimi"/>
                    </Input>

                    <Input
                        name="discount"
                        label="label"
                        id="commentsInput"
                        inputWrapperClass="offerInputWrapper smallerInput smallerWidth mobile"
                        value={formatNumberPrice(String(product.discount), 2) || ''}
                        onChange={(name, value) => handleChange(name, value)}
                        defaultValue=""
                        type="number"
                        min={0}

                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="app.table.nameSurname" defaultMessage="Zbritje %"/>
                    </Input>

                    <Input
                        name="tax"
                        label="label"
                        id="commentsInput"
                        inputWrapperClass="offerInputWrapper smallerInput smallerWidth mobile"
                        value={formatNumberPrice(String(product.tax), 2) || ''}
                        onChange={(name, value) => handleChange(name, value)}
                        defaultValue=""
                        type="number"
                        min={0}

                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="table.nameSurname" defaultMessage="TVSH %"/>
                    </Input>
                    <Input
                        name="totalPrice"
                        label="label"
                        id="commentsInput"
                        inputWrapperClass="offerInputWrapper smallerInput taxPrice medium mobile discountInput"
                        value={toFixedNumber(calculatedTotalProductPrice, 2) || ''}
                        defaultValue=""
                        type="number"
                        disabled
                        min={0}
                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="table.nameSurname" defaultMessage="Totali"/>
                        {props?.discount && (!product?.canceled) &&
                            <p className={'discountValue'}>{(+props?.discount - +calculatedTotalProductPrice)?.toFixed(2)}</p>}
                    </Input>
                    <Input
                        name="start"
                        label="label"
                        id="start"
                        inputWrapperClass="offerInputWrapper smallerInput startDate mobile"
                        value={(product?.start && formatDate(product?.start)) || ''}
                        defaultValue=""
                        // type="date"
                        disabled
                        inputTextType={EInputTextType.SimpleInput}>
                        {forProduction ?
                            <TranslatedText id="client.scanDocument" defaultMessage="Data e fillimit"/>
                            :
                            <TranslatedText id="client.scanDocument" defaultMessage="Data e porosise"/>
                        }
                        {product?.initialStart && <div className={'initialDate'}>
                            <Tooltip placement="right" overlay={<span>Data fillestare e fillimit</span>}>
                                <p>{moment(product?.initialStart)?.format("DD-MM-YYYY")}</p>
                            </Tooltip>
                        </div>}
                    </Input>
                    <Input
                        name="end"
                        label="label"
                        id="end"
                        inputWrapperClass="offerInputWrapper smallerInput endDate mobile"
                        value={(product?.end && formatDate(product?.end)) || ''}
                        defaultValue=""
                        // type="text"
                        disabled
                        inputTextType={EInputTextType.SimpleInput}>
                        {forProduction ?
                            <TranslatedText id="client.scanDocument" defaultMessage="Data e mbarimit"/>
                            :
                            <TranslatedText id="client.scanDocument" defaultMessage="Data e liferimit"/>
                        }
                        {product?.initialEnd && <div className={'initialDate'}>
                            <Tooltip placement="right" overlay={<span>Data fillestare e mbarimit</span>}>
                                <p>{moment(product?.initialEnd)?.format("DD-MM-YYYY")}</p>
                            </Tooltip>
                        </div>}
                    </Input>
                    {((!props.permissions.observer) || props.permissions.fullPrivileges) && <>
                        <Button
                            isLoading={buttonLoader}
                            isDisabled={buttonLoader}
                            onClick={saveProduct}
                            className={`button-save-offer w-10  align-items-center calculateOfferButton py-2 text-center ${product?.id ? 'editButton' : ''}`}>
                            {product?.id ?
                                <Tooltip placement="top" overlay={<span><TranslatedText id="offer.saveOffer" defaultMessage="Ruaj Produktin"/></span>}>
                                    <FontAwesome name={'save'}/>
                                </Tooltip>
                                :
                                <TranslatedText id="offer.saveOffer" defaultMessage="Ruaj Produktin"/>
                            }
                        </Button>
                        {product?.id && <Button
                            isLoading={buttonLoader}
                            isDisabled={buttonLoader}
                            onClick={manuallyUpdateStartAndEndDates}
                            className="button-save-offer w-10  align-items-center calculateOfferButton py-2 text-center ">
                            <TranslatedText id="offer.saveOffer" defaultMessage="Ndrysho datat"/>
                        </Button>}
                    </>}
                </div>
                <hr className="mx-auto product-separation-line"/>
            </div>
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteProduct()}/>
            </ModalComponent>
            <ModalComponent
                isModalHeader
                isOpen={isCancelModalOpen}
                close={setCancelModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setCancelModal(false)}
                    deleteFunction={() => cancelProduct()}/>
            </ModalComponent>
        </div>
    );
};

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        offers: state.offers,
        admin: state.admin,
        client: state.client
    };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        offerActions: bindActionCreators(offerActions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch),
    };
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(Product as any)));
