import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, {useEffect, useState} from "react";
import {FormattedMessage, injectIntl} from 'react-intl'
import {Dispatch, bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as productionActions from '../../actions/production';
import {RootState} from '../../reducers';
import './calendarComponent.scss'
import {axiosCaller} from "../../utils/apiCaller";
import {toast} from "react-toastify";
import CalendarEvent from "./CalendarEvent";
import PlusIconLink from "../../assets/icons/plus.svg";
import {Input} from "../Input/Input";
import {Button, EButtonType} from "../Button";
import moment from "moment";
import Spinner from "../Spinner";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import HotkeyButton from "../HotkeyButton/HotkeyButton";
import MikaSelect from "../BbrosSelect/mikaSelect.component";
import {SelectOption} from "../../types/selectOption.type";
import TranslatedText from "../TranslatedText";
import {getMonthsBetween} from "../../containers/Admin/globalFunctions";

interface Props {
    loading: boolean
    actions: any,
    calendarData: any[],
    calendarDataPerClient: any[],
    client: any,
    clientActions: any,
    productionActions: any;
    intl: any
    buttonLoader: boolean
    departments: any
}

const CalendarComponent = (props: Props) => {

    const [calendarEvents, setCalendarEvents] = useState([])
    const [selectedProduct, setSelectedProduct] = useState<any>()
    const [productIdForModal, setProductIdForModal] = useState<number>()
    const [selectedDepartment, setSelectedDepartment] = useState<SelectOption>({} as SelectOption)
    const [startDate, setStartDate] = useState<any>()
    const [calendarComment, setCalendarComment] = useState('')
    const [initialStartDate, setInitialStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [productData, setProductData] = useState<any>()
    const [calendarDateView, setCalendarDateView] = useState(moment().format('YYYY-MM-DD'))
    const currentDate = moment().format('YYYY-MM-DD')
    const [showYearlyProduction, setShowYearlyProduction] = useState<boolean>(false)
    const [startMonth, setStartMonth] = useState<any>()
    const [endMonth, setEndMonth] = useState<any>()
    const [selectedMonths, setSelectedMonths] = useState([])

    useEffect(() => {
        props.actions.fetchCalendarData()
        props.actions.getAllDepartments()
    }, [])

    useEffect(() => {
        props.actions.fetchCalendarData(selectedDepartment)
    }, [selectedDepartment])

    useEffect(() => {
        let calendarProduct = {
            startEditable: true,
            start: '',
            end: '',
            productName: '',
            clientName: '',
            id: null,
            projectName: '',
            calendarComment: '',
            statuses: [],
            endingDateString: '',
            measureDone: true
        }

        let calendarEventArray = []

        props.calendarData.map((product) => {
            let endingDate = moment(product.end).add(1, 'day').format("YYYY-MM-DD")
            calendarProduct = {
                ...calendarProduct,
                clientName: product.client?.name,
                projectName: product?.project?.name,
                id: product?.id,
                productName: product?.designation?.name,
                start: product?.start,
                calendarComment: product?.calendarComment,
                end: endingDate,
                statuses: product?.statuses,
                endingDateString: product?.end,
                measureDone: product?.measureStatus === 2
            }
            if(product?.statuses?.length !== 0){
                calendarEventArray.push(calendarProduct)
            }
        })
        setCalendarEvents(calendarEventArray)
    }, [props.calendarData])

    function addDays(date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
    function saveChangedDate(calendarEvent) {
        let tempStartDate = addDays(new Date(selectedProduct?.start), calendarEvent.delta.days)
        let today = new Date();
        today.setDate(today.getDate() - 1);
        let startMilliseconds = new Date(tempStartDate).getTime()
        if(startMilliseconds < new Date(today).getTime()){
            props.actions.fetchCalendarData(selectedDepartment)
            toast.error('Nuk mund ta vendosni ne te kaluaren!')
            return
        }

        let payload = {
            productId: selectedProduct?.id,
            days: calendarEvent.delta.days
        }

        axiosCaller('POST', `admin/products/change-date-on-calendar`, payload).then((result) => {
            let scroll = document.getElementsByClassName('fc-scroller-harness fc-scroller-harness-liquid')[0].scrollTop
            props.actions.fetchCalendarData(selectedDepartment).then(()=>{
                document.getElementsByClassName('fc-scroller-harness fc-scroller-harness-liquid')[0].scrollTop = scroll
            })
            closeUpdateDatesModal()
            if (result.data.data) {
                toast.success('Data u ndryshua me sukses!')
            } else {
                toast.error('Diqka shkoi keq!')
            }
        })
    }

    function renderEventContent(eventInfo) {
        return (
            <CalendarEvent eventInfo={eventInfo} productId={productIdForModal} setProductId={setProductIdForModal}
                           setStartDate={setStartDate} setInitialStartDate={setInitialStartDate} setEndDate={setEndDate} setCalendarComment={setCalendarComment} setProductData={setProductData} />
        )
    }

    function saveDates() {

        if(startDate || endDate){
            if(!calendarComment){
                toast.info('Ju lutem vendosni nje koment per ndryshime te datave!')
                return;
            }
        }

        let startDateMs = new Date(startDate)?.getTime()
        let endDateMs = new Date(endDate)?.getTime()

        if(startDateMs > endDateMs){
            toast.error('Data e mbarimit smund te jete para fillimit!')
            return;
        }

        if( (startDate || initialStartDate) && endDate ){
            if(new Date(startDate).getTime() + 86400000 < new Date()?.getTime()){
                toast.info('Data e fillimit duhet te jete ne te ardhmen!')
                return
            }

            let payload = {
                productId: Number(productIdForModal),
                start: startDate ? moment(startDate).format('YYYY-MM-DD') : moment(initialStartDate).format('YYYY-MM-DD'),
                end: moment(endDate).format('YYYY-MM-DD'),
                calendarComment: calendarComment
            }

            props.productionActions.updateProductDates(payload).then((res) => {
                if (res?.data?.data) {
                    let scroll = document.getElementsByClassName('fc-scroller-harness fc-scroller-harness-liquid')[0].scrollTop
                    props.actions.fetchCalendarData(selectedDepartment).then(() => {
                        document.getElementsByClassName('fc-scroller-harness fc-scroller-harness-liquid')[0].scrollTop = scroll
                        toast.success('Data eshte edituar')
                        closeUpdateDatesModal()
                    })
                } else {
                    toast.error('Keto data jane te zena!')
                }
            })
        }else{
            toast.info('Ju lutem mbushni fushat e datave!')
        }
    }

    // const months = [
    //     {
    //         label: "January",
    //         value:  `${currentDate.split('-')[0]}-01-01`
    //     },
    //     {
    //         label: "February",
    //         value:  `${currentDate.split('-')[0]}-02-01`
    //     },
    //     {
    //         label: "March",
    //         value:  `${currentDate.split('-')[0]}-03-01`
    //     },
    //     {
    //         label: "April",
    //         value:  `${currentDate.split('-')[0]}-04-01`
    //     },
    //     {
    //         label: "May",
    //         value:  `${currentDate.split('-')[0]}-05-01`
    //     },
    //     {
    //         label: "June",
    //         value:  `${currentDate.split('-')[0]}-06-01`
    //     },
    //     {
    //         label: "July",
    //         value:  `${currentDate.split('-')[0]}-07-01`
    //     },
    //     {
    //         label: "August",
    //         value:  `${currentDate.split('-')[0]}-08-01`
    //     },
    //     {
    //         label: "September",
    //         value:  `${currentDate.split('-')[0]}-09-01`
    //     },
    //     {
    //         label: "October",
    //         value:  `${currentDate.split('-')[0]}-10-01`
    //     },
    //     {
    //         label: "November",
    //         value:  `${currentDate.split('-')[0]}-11-01`
    //     },
    //     {
    //         label: "December",
    //         value:  `${currentDate.split('-')[0]}-12-01`
    //     }
    // ]

    function selectCalendarView(monthView){
        setCalendarDateView(monthView)
        setShowYearlyProduction(false)
    }

    function closeUpdateDatesModal() {
        setProductIdForModal(null);
        setCalendarComment('');
        setInitialStartDate('')
        setStartDate('')
        setEndDate('')
        setProductData(null)
    }

    const objectWithMonths = {
        "January": "01",
        "February": "02",
        "March": "03",
        "April": "04",
        "May": "05",
        "June": "06",
        "July": "07",
        "August": "08",
        "September": "09",
        "October": "10",
        "November": "11",
        "December": "12"
    };

    function filterMonths(){
        if(startMonth && endMonth){
            setSelectedMonths([])
            setTimeout(()=>{
                let arrayOfMonths = getMonthsBetween(startMonth, endMonth)
                let arrayOfMonthsForCalendar = []
                arrayOfMonths?.forEach((month)=>{
                    console.log(currentDate, month.split(' '))
                    arrayOfMonthsForCalendar.push({
                        label: month?.split(' ')[0],
                        value: `${month?.split(' ')[1]}-${objectWithMonths[month?.split(' ')[0]]}-01`
                    })
                })
                setShowYearlyProduction(true)
                setSelectedMonths(arrayOfMonthsForCalendar)
            })
        }else{
            setShowYearlyProduction(false)
        }
    }

    return (
        <div className="calendar-container">
            <div className={`showYearlyProductionButton ${showYearlyProduction ? "show": "hide"}`}>
                <input type="month" value={startMonth} onChange={(event)=> setStartMonth(event.target?.value)}/>
                <input type="month" value={endMonth} onChange={(event)=>setEndMonth(event.target?.value)} />
                <button onClick={filterMonths} className={'filterMonths'}>Filtro</button>
            </div>

            {/*{showYearlyProduction && <div className={'monthFilters'}>*/}
            {/*    <input type="month" value={startMonth} onChange={(value)=>setStartMonth(value)} />*/}
            {/*    <input type="month" value={endMonth} onChange={(value)=>setEndMonth(value)} />*/}
            {/*    <button className={''}>Filtro</button>*/}
            {/*</div>}*/}

            <div className={`selectDepartmentButton ${showYearlyProduction ? "show": "hide"}`}>
                <MikaSelect options={props?.departments} placeholder={'Reparti'} value={selectedDepartment} setEntireOptionFunction={setSelectedDepartment} />
            </div>

            { showYearlyProduction ?
                <div className={'calendar-wrapper'}>
                    { selectedMonths?.map((month)=>{
                        return <div className={'calendar-holder'} onClick={()=>selectCalendarView(month.value)}>
                            <FullCalendar
                                plugins={[daygridPlugin]}
                                eventContent={renderEventContent}
                                eventDrop={saveChangedDate}
                                events={calendarEvents}
                                initialDate={month.value}
                                eventDragStart={(eventInfo: any) => setSelectedProduct(eventInfo.event)}
                                headerToolbar={{
                                    right: "prev,next",
                                    center: "title",
                                    left: "dayGridMonth"
                                }}
                            />
                        </div>
                    }) }
                </div>:
                <FullCalendar
                    plugins={[daygridPlugin, interactionPlugin, timeGridPlugin]}
                    eventContent={renderEventContent}
                    eventDrop={saveChangedDate}
                    events={calendarEvents}
                    initialDate={calendarDateView}
                    eventDragStart={(eventInfo: any) => setSelectedProduct(eventInfo.event)}
                    headerToolbar={{
                        right: "prev,next",
                        center: "title",
                        left: "dayGridMonth,timeGridWeek,timeGridDay"
                    }}
                />
            }

            {productIdForModal && <div className={'updateDatesModalContainer'}>
                <div className={'updateDatesModal'}>
                    <div className={"d-flex justify-content-between"}>
                        <div className={'w-50'}>
                            <h4 className={'m-0'}><TranslatedText id="productData"
                                                                    defaultMessage="Te dhenat e produktit"/></h4>
                        </div>
                        <div className="x-sign w-50">
                            <HotkeyButton
                                keyCode="Escape"
                                modifiers={[]}
                                isActive={false}
                                onStateChange={(state) => {
                                    if(state){
                                        closeUpdateDatesModal()
                                    }
                                }}
                            >
                                <img className={'x-sign'} src={DifferentColoredPlus} alt="" onClick={() => {
                                    closeUpdateDatesModal()
                                }}/>
                            </HotkeyButton>
                        </div>
                    </div>
                    {props.loading ? <Spinner/> :
                        <>
                            <div className={'dateInputContainer py-3'}>
                                <div>
                                    <p>{productData.extendedProps?.clientName}</p>
                                    <p>{productData.extendedProps?.productName}</p>
                                    <p>Progresi:</p>
                                    <div className={'progressBarContainer'}>
                                        <div className="progressBar" style={{width: `${productData?.progressInPercentage?.replace(' ','')}`}}>
                                            <div className={'percentageText'}>
                                                <p>{productData?.progressInPercentage !== 'NaN %' && productData?.progressInPercentage}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'d-flex justify-content-between align-items-center'}>
                                    <Input
                                        name="date"
                                        value={startDate ? startDate || '' : initialStartDate || ''}
                                        type="date"
                                        onChange={(name, value) => setStartDate(value)}
                                        inputWrapperClass={`simple-date-input mt-0 heightFitContent ${startDate ? " " : 'does-not-have-data'}`}>
                                        <TranslatedText id="startDate" defaultMessage="Data e fillimit *"/>
                                    </Input>
                                    <Input
                                        name="date"
                                        value={endDate || ''}
                                        type="date"
                                        onChange={(name, value) => setEndDate(value)}
                                        inputWrapperClass={`simple-date-input mt-0 heightFitContent ${endDate ? " " : 'does-not-have-data'}`}>
                                        <TranslatedText id="endDate" defaultMessage="Data e mbarimit *"/>
                                    </Input>
                                </div>
                                <div>
                                    <Input
                                        name="comments"
                                        label="label"
                                        id="comments"
                                        defaultValue=''
                                        inputWrapperClass={"w-100 textarea-wrapper"}
                                        type="textarea"
                                        value={calendarComment || ''}
                                        onChange={(name, value) => setCalendarComment(value)}
                                    >
                                        <TranslatedText id="appendFinalAgreement" defaultMessage="Koment per ndyshim te datave"/>
                                    </Input>
                                </div>
                            </div>
                            <div className={'d-flex justify-content-end'}>
                                <Button
                                    isLoading={props?.buttonLoader}
                                    isDisabled={props?.buttonLoader}
                                    className="m-auto w-100"
                                    onClick={saveDates}
                                    buttonType={EButtonType.GreyButton}>
                                    <TranslatedText id="saveDates" defaultMessage="Ruaj datat"/>
                                </Button>
                            </div>
                        </>
                    }
                </div>
            </div>}
            <div className="legend">
                <div>
                    <div className="legendIcon completed"></div>
                    <p><TranslatedText id="header.from" defaultMessage="Perfunduar me sukses"/></p>
                </div>
                <div>
                    <div className="legendIcon toDo"></div>
                    <p><TranslatedText id="app.header.from" defaultMessage="Per tu realizuar"/></p>
                </div>
                <div>
                    <div className="legendIcon late"></div>
                    <p><TranslatedText id="app.header.from" defaultMessage="Me vonese"/></p>
                </div>
                <div>
                    <div className="legendIcon inProcess"></div>
                    <p><TranslatedText id="app.header.from" defaultMessage="Ne proces"/></p>
                </div>
            </div>
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        productionActions: bindActionCreators(productionActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(CalendarComponent as any)));